import fetch from './fetch';
import dayjs from 'dayjs';
import { Message } from '@pathccm/path-components-messaging';
import { MessagingParticipantType } from '@/utils/types';

export interface SendMessageApiResponse {
  message_thread_uuid: string;
  author: {
    id: string;
    type: MessagingParticipantType;
  };
  created_at: string;
  message_uuid: string;
  message: string;
}

// Messaging

export const getMessageThread = async (
  uuid: string,
  patientUuid: string,
  nextTimestamp?: string
) => {
  const response = await fetch.json(
    '/api/message_thread?' +
      new URLSearchParams({
        message_thread_uuid: uuid || '',
        patient_uuid: patientUuid,
        ...(nextTimestamp && { end_timestamp: nextTimestamp }),
      }),
    { method: 'GET' }
  );
  const {
    message_thread_uuid,
    patient_uuid,
    provider_uuid,
    unread_count,
    participants,
    has_more,
    next_end_timestamp,
    messages,
  } = response.payload;
  const thread = {
    uuid: message_thread_uuid,
    patientUuid: patient_uuid,
    providerUuid: provider_uuid,
    unreadCount: unread_count,
    participants: participants,
    hasMore: has_more,
    nextTimestamp: next_end_timestamp
      ? new Date(next_end_timestamp)
      : undefined,
    messages: messages.length
      ? messages
          .map(
            (m: {
              message_uuid: string;
              author: { id: string; type: string };
              created_at: string;
              message_text: string;
            }) => ({
              uuid: m.message_uuid,
              author: m.author,
              createdAt: m.created_at,
              messageText: m.message_text,
            })
          )
          .sort(
            (a: Message, b: Message) =>
              dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix()
          )
      : [],
  };
  return thread;
};

export const setThreadAsRead = (uuid: string, patient_uuid: string) =>
  fetch.json('/api/message_thread/read', {
    method: 'POST',
    body: {
      patient_uuid,
      message_thread_uuid: uuid || '',
    },
  });

export const getAllThreads = async (patient_uuid: string) => {
  const response = await fetch.json(
    '/api/message_threads?' +
      new URLSearchParams({
        patient_uuid,
      }),
    {
      method: 'GET',
    }
  );
  const threads = response.payload.map(
    ({
      provider_uuid,
      patient_uuid,
      unread_count,
      uuid,
      last_sent,
    }: {
      provider_uuid: string;
      patient_uuid: string;
      unread_count: number;
      uuid: string;
      last_sent: string;
    }) => ({
      providerUuid: provider_uuid,
      patientUuid: patient_uuid,
      unreadCount: unread_count,
      uuid,
      lastSent: last_sent,
    })
  );
  return threads;
};

export const sendMessage = async (thread: {
  message: string;
  patient_uuid: string;
  provider_uuid?: string;
  thread_id?: string;
}): Promise<Message & { uuid: string; messageThreadUuid?: string }> => {
  const API_ENDPOINT = thread.thread_id
    ? '/api/message_threads/new_message'
    : '/api/message_threads';

  const response = await fetch.json(API_ENDPOINT, {
    method: 'POST',
    body: thread,
  });
  const { payload } = response;

  return {
    uuid: payload.message_uuid,
    author: payload.author,
    createdAt: payload.created_at,
    messageText: payload.message,
    messageThreadUuid: payload.message_thread_uuid,
  };
};
