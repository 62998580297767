import actions from '@/state/actions';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { MobileContext, StateContext } from '@/App';
import Text from '@/components/Text';
import { AnalyticsEvent } from '@/utils/types';
import trackEvent from '@/utils/amplitude';

interface PersonalDetailsBannerProps {
  detailsToEdit: ('creditCard' | 'profile' | 'insurance')[];
}

const PersonalDetailsBanner = ({
  detailsToEdit,
}: PersonalDetailsBannerProps) => {
  const { dispatch } = useContext(StateContext);
  const mobile = useContext(MobileContext);
  const TextComponent = mobile ? Text.Small : Text.P;
  const UnderlineTextComponent = mobile
    ? Text.Small.Bold.Underline
    : Text.P.Inline.Bold.Underline;
  const clickPersonalDetailsBanner: AnalyticsEvent = {
    product_area: 'Billing',
    name: 'Card_status_banner_click',
    trigger: 'Interaction',
  };

  return (
    <TextComponent className="text-center text-warning-1 p-1">
      Your profile is missing important insurance and payment information.
      Please{' '}
      <UnderlineTextComponent>
        {/* Note: the scroll behavior only works after the /profile page has been rendered one time because it does not exist in the DOM yet */}
        <Link
          to="/profile"
          onClick={() => {
            if (detailsToEdit?.includes('profile')) {
              dispatch(actions.profile.setDetailsEditMode('edit'));
            }
            if (detailsToEdit?.includes('insurance')) {
              dispatch(actions.profile.setInsuranceEditMode('edit'));
              setTimeout(() => {
                const insuranceSection =
                  document.getElementById('edit-insurance');
                if (insuranceSection) {
                  insuranceSection.scrollIntoView({ behavior: 'smooth' });
                }
              }, 100);
            }
            if (detailsToEdit?.includes('creditCard')) {
              dispatch(actions.profile.setCcEditMode('edit'));
              if (!detailsToEdit?.includes('insurance')) {
                // do not scroll to credit card if we are already scrolling to insurance
                setTimeout(() => {
                  const creditCardSection =
                    document.getElementById('edit-credit-card');
                  if (creditCardSection) {
                    creditCardSection.scrollIntoView({ behavior: 'smooth' });
                  }
                }, 100);
              }
            }

            trackEvent(clickPersonalDetailsBanner);
          }}
        >
          <u>update your profile</u>
        </Link>
      </UnderlineTextComponent>{' '}
      to continue care.
    </TextComponent>
  );
};
export default PersonalDetailsBanner;
