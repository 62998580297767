import React from 'react';
import Text from '@/components/Text';
import CheckCircle from '@/components/icons/CheckCircle';
import { ReactChildren } from '@/utils/types';

interface Props {
  children: ReactChildren | React.ReactNode;
}

export default function CheckboxPill({ children }: Props) {
  return (
    <div className="mb-10 md:my-4 flex md:flex flex-wrap justify-center md:justify-start">
      <div className="flex flex-none justify-center py-1 pl-2 pr-4  grow-0 rounded-[20px] bg-secondary-0 mx-1 mb-2 md:mx-0 md:mr-2">
        <CheckCircle
          className="flex-none h-4 self-center"
          stroke="secondary-5"
        />
        <Text.Small className="flex-auto text-secondary-5 md:text-left">
          {children}
        </Text.Small>
      </div>
    </div>
  );
}
