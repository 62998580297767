import { useContext, useEffect } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { MobileContext, StateContext } from './App';
import PageLayout from './components/PageLayout';
import Nav from './Nav';
import Billing from './pages/Billing';
import Profile from './pages/Profile';
import Appointments from './pages/appointments/Appointments';
import CareTeamV2 from './pages/CareTeamV2';
import actions from './state/actions';
import UserMenu from './UserMenu';
import fetch from './utils/fetch';
import ScrollToTop from './utils/navigation';
import useData, { useLoadData } from './state/use-data';
import InitialReplacementPromptV2 from './pages/care-team/replace-flow/InitialReplacementPromptV2';
import FindProviderV2 from './pages/care-team/replace-flow/FindProviderV2';
import { default as ReplaceChooseAppointmentV2 } from './pages/care-team/replace-flow/ChooseAppointmentV2';
import { default as ReplaceConfirmAppointmentV2 } from './pages/care-team/replace-flow/ConfirmRematchAppointmentV2';
import ConfirmNewCareTypeAppointmentV2 from './pages/care-team/replace-flow/ConfirmNewCareTypeAppointmentV2';
import { useIdentifyUser } from './utils/use-feature-flags';
import Loader from './components/Loader';
import ChooseAppointmentV2 from './pages/appointments/reschedule/ChooseAppointmentV2';
import ConfirmAppointmentV2 from './pages/appointments/reschedule/ConfirmAppointmentV2';
import Messaging from './pages/Messaging';
import ProviderProfileV2 from './pages/care-team/ProviderProfileV2';
import ChooseAppointmentWithoutPrevious from './pages/appointments/book-new-followup/ChooseAppointmentWithoutPrevious';
import ConfirmAppointmentWithoutPrevious from './pages/appointments/book-new-followup/ConfirmAppointmentWithoutPrevious';
import trackEvent, { identifyAmplitudeUser } from './utils/amplitude';

const Container = () => {
  const { state, dispatch } = useContext(StateContext);
  const mobile = useContext(MobileContext);

  // provides useEffects for all server data to load data when needed
  useLoadData();

  const {
    data: { patientData, profile },
  } = useData(['patientData', 'profile']);

  useEffect(() => {
    if (patientData.loadingState === 'done') {
      // TODO only do this once per session
      const generatePdf = () =>
        fetch.json('/api/generate_billing_pdfs', {
          method: 'POST',
          body: patientData,
        });
      // retry once after 5 seconds if the initial call fails
      generatePdf().catch(() => setTimeout(generatePdf, 5000));
    }
  }, [patientData.loadingState]);

  useEffect(() => {
    identifyAmplitudeUser(state.session);
    trackEvent({
      product_area: 'SignIn',
      name: 'Patient_signed_in',
      trigger: 'Interaction',
    });
  }, []);

  const identified = useIdentifyUser(patientData, profile);

  if (!identified) {
    return <Loader.Contained />;
  }

  const setNavOpen = (o: boolean) => dispatch(actions.setHamburgerMenuOpen(o));
  const layout = (
    <div>
      {mobile ? (
        <PageLayout.Mobile
          nav={<Nav onClick={() => setNavOpen(!state.ui.hamburgerMenuOpen)} />}
          Logout={UserMenu.Mobile}
          setNavOpen={setNavOpen}
          navOpen={state.ui.hamburgerMenuOpen}
          onClick={() => dispatch(actions.closeMenus())}
          onCloseModal={() => dispatch(actions.closeModal())}
          toast={state.toast}
          modal={state.ui.modal}
          banner={state.ui.banner}
        >
          <Outlet />
        </PageLayout.Mobile>
      ) : (
        <PageLayout
          nav={<Nav />}
          TopRightMenu={UserMenu}
          onClick={() => dispatch(actions.closeMenus())}
          onCloseModal={() => dispatch(actions.closeModal())}
          toast={state.toast}
          modal={state.ui.modal}
          banner={state.ui.banner}
        >
          <Outlet />
        </PageLayout>
      )}
    </div>
  );

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route element={layout}>
          {/* NOTE - you need to make sure any new routes here also get routed properly in gateway/src/public-routes.ts */}
          <Route index={true} element={<Appointments />}></Route>
          <Route path="appointments" element={<Appointments />}></Route>
          <Route path="messages">
            <Route index element={<Messaging />} />
            <Route path=":providerUuid" element={<Messaging />} />
          </Route>
          <Route path="profile" element={<Profile />}></Route>
          <Route path="billing" element={<Billing />}></Route>
          <Route path="care-team" element={<CareTeamV2 />}></Route>

          <Route
            path="care-team/:providerNpi/replace"
            element={<InitialReplacementPromptV2 />}
          ></Route>

          {/* CareTeam old routes drop back to new replacement flow */}

          <Route
            path="care-team/:providerNpi/replace/find-new-provider"
            element={<InitialReplacementPromptV2 />}
          ></Route>
          <Route
            path="care-team/:providerNpi/replace/:newNpi/choose-appointment"
            element={<InitialReplacementPromptV2 />}
          ></Route>
          <Route
            path="care-team/:providerNpi/replace/:newNpi/confirm-appointment/:appointment"
            element={<InitialReplacementPromptV2 />}
          ></Route>

          {/* CareTeam V2 Replace Flow */}

          <Route
            path="care-team/:providerNpi/:therapyType/replace/find-new-provider"
            element={<FindProviderV2 />}
          ></Route>
          <Route
            path="care-team/:providerNpi/:therapyType/replace/:newNpi/choose-appointment"
            element={<ReplaceChooseAppointmentV2 />}
          ></Route>
          <Route
            path="care-team/:providerNpi/:therapyType/replace/:newNpi/confirm-appointment/:appointment"
            element={<ReplaceConfirmAppointmentV2 />}
          ></Route>

          {/* Add Care Type Flow */}
          <Route
            path="care-team/:therapyType/add-new-care-type/find-new-provider"
            element={<FindProviderV2 />}
          ></Route>
          <Route
            path="care-team/:therapyType/add-new-care-type/:newNpi/choose-appointment"
            element={<ReplaceChooseAppointmentV2 />}
          ></Route>
          <Route
            path="care-team/:therapyType/add-new-care-type/:newNpi/confirm-appointment/:appointment"
            element={<ConfirmNewCareTypeAppointmentV2 />}
          ></Route>

          <Route
            path="care-team/:providerNpi"
            element={<ProviderProfileV2 />}
          ></Route>
          <Route
            path="appointments/reschedule/:providerNpi/:appointmentUUID"
            element={<ChooseAppointmentV2 />}
          ></Route>
          <Route
            path="appointments/reschedule/:providerNpi/:appointmentUUID/:newAppointmentUnix"
            element={<ConfirmAppointmentV2 />}
          ></Route>

          {/* Deeplinks for psych followup booking */}
          <Route
            path="appointments/schedule-follow-up/:providerNpi/:therapyType"
            element={<ChooseAppointmentWithoutPrevious />}
          ></Route>
          <Route
            path="appointments/schedule-follow-up/:providerNpi/:therapyType/:appointmentUnix"
            element={<ConfirmAppointmentWithoutPrevious />}
          ></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Container;
