import Icon, { IconProps } from './Icon';

/**
 * NOTE: The transform="rotate()" property has been removed because it doesn't
 * work in Safari.
 *
 * To rotate the chevron use the tailwind css class supplied to us.
 *
 * The options are:
 * down: (default), left: "rotate-90", right: "-rotate-90", up: "rotate-180"
 */
const Chevron = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M17 7L10 13L3 7"
        strokeWidth="1.5"
        strokeLinecap="round"
        className="translate-x-[2px] translate-y-[3px]"
      />
    </Icon>
  );
};

export default Chevron;
