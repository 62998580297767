type Value = string | number | boolean | Obj | Arr | null;

export interface Obj {
  [x: string]: Value | undefined;
}

type Arr = Array<Value>;

function isObj(v: Value | undefined): v is Obj {
  return v !== null && typeof v === 'object' && !Array.isArray(v);
}

function setDefaults(obj: Obj, defaultValue: Value): Record<string, Value> {
  return Object.keys(obj).reduce((o: Record<string, Value>, k) => {
    const v = obj[k];
    if (isObj(v)) {
      o[k] = setDefaults(v, defaultValue);
    } else {
      o[k] = typeof v === 'undefined' ? defaultValue : v;
    }
    return o;
  }, {});
}

export default setDefaults;
