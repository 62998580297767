import { useEffect, useMemo } from 'react';

export type ValidationError = {
  message: string;
  keys: string[];
};

export const isError = (validations: ValidationError[]) => (key: string) =>
  validations.find(({ keys }) => keys.includes(key));

export const makeErrorState =
  (invalid: boolean, validations: ValidationError[]) =>
  (key: string, dflt = '' as const) =>
    invalid && isError(validations)(key) ? ('error' as const) : dflt;

const useValidation =
  (setValid: () => unknown) =>
  (validate: () => ValidationError[], dependencies: any[]) => {
    const validation = useMemo(validate, dependencies);
    useEffect(() => {
      if (!validation.length) {
        setValid();
      }
    }, dependencies);
    return validation;
  };

export default useValidation;
