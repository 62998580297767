import Icon, { IconProps } from './Icon';

const AddAccount = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 36 36" {...props}>
      <path
        d="M18 23.25H11.25C9.15666 23.25 8.10998 23.25 7.25829 23.5084C5.34068 24.0901 3.84006 25.5907 3.25836 27.5083C3 28.36 3 29.4067 3 31.5M28.5 31.5V22.5M24 27H33M21.75 11.25C21.75 14.9779 18.7279 18 15 18C11.2721 18 8.25 14.9779 8.25 11.25C8.25 7.52208 11.2721 4.5 15 4.5C18.7279 4.5 21.75 7.52208 21.75 11.25Z"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default AddAccount;
