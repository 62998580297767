import { surveysUrl } from '@/utils/constants';
import Text from './Text';
import CheckCircle from './icons/CheckCircle';
import { twMerge } from 'tailwind-merge';
import RightArrow from './icons/RightArrow';
import { useContext, useEffect } from 'react';
import { MobileContext } from '@/App';
import trackEvent from '@/utils/amplitude';

interface Props {
  checkedIn: boolean;
  appointmentUuid: string;
  extraStyles?: string;
  appointmentTime: string;
  providerNpi: number;
}

const CheckInBanner = ({
  checkedIn,
  appointmentUuid,
  extraStyles,
  appointmentTime,
  providerNpi,
}: Props) => {
  useEffect(() => {
    trackEvent({
      product_area: 'Appointments',
      name: 'checkin_displayed',
      trigger: 'Page load',
      metadata: {
        appointmentTime,
        checkedIn,
      },
    });
  }, []);

  const mobile = useContext(MobileContext);
  const handleClick = () => {
    if (!checkedIn) {
      trackEvent({
        product_area: 'Appointments',
        name: 'checkin_clicked',
        trigger: 'Interaction',
        metadata: {
          appointmentTime,
          providerNpi,
        },
      });
      window.location.href = `${surveysUrl}/${appointmentUuid}`;
    }
  };
  const checkInText = mobile
    ? 'Time to check in!'
    : 'Time to check in for your appointment!';

  const contents = checkedIn ? (
    <>
      <Text.P.Bold>You're checked In!</Text.P.Bold>
      <CheckCircle stroke="secondary-4" />
    </>
  ) : (
    <>
      <Text.P.Bold>{checkInText}</Text.P.Bold>
      <RightArrow
        className="transition-transform ease-in-out duration-300 group-hover:translate-x-[3px]"
        stroke="primary-3"
      />
    </>
  );

  const classes = checkedIn
    ? 'text-secondary-4 bg-secondary-0'
    : 'group text-primary-3 bg-primary-0 cursor-pointer transition-colors ease-in-out background-color hover:bg-primary-5';

  return (
    <div
      className={twMerge(
        'w-full h-16 flex justify-between items-center px-9',
        classes,
        extraStyles
      )}
      onClick={handleClick}
      role={!checkedIn ? 'button' : undefined}
    >
      {contents}
    </div>
  );
};

export default CheckInBanner;
