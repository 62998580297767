import { ReactChildren } from '@/utils/types';
interface PillProps {
  children: string | ReactChildren;
  onClick?: () => void;
  size?: 'normal' | 'small';
  variant?: 'primary' | 'secondary' | 'warning' | 'primary-no-outline';
}
const Pill = ({
  children,
  onClick,
  size = 'normal',
  variant = 'primary',
}: PillProps) => {
  const color =
    variant === 'primary'
      ? 'text-primary-3 bg-primary-0'
      : variant === 'warning'
      ? 'text-warning-1 bg-warning-0'
      : variant === 'primary-no-outline'
      ? 'text-primary-3 bg-primary-0 border-0'
      : 'border-tertiary-2 text-tertiary-6';

  const sizeClasses =
    size === 'small' ? 'py-[2px] px-2 text-[12px]' : 'py-1 px-2 text-sm';

  const classes = `rounded-sm border-1 whitespace-nowrap text-center rounded-[2rem] ${color} ${sizeClasses}`;

  return (
    <div
      role={onClick ? 'button' : 'none'}
      onClick={onClick}
      className={classes}
    >
      {children}
    </div>
  );
};

export default Pill;
