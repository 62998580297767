import { supportEmail } from '@/utils/constants';
import MailToLink from './MailToLink';

export interface SupportLinkProps {
  email?: string;
  text?: string;
}

const SupportLink = ({
  email = supportEmail,
  text = supportEmail,
}: SupportLinkProps) => {
  return <MailToLink email={email} text={text} />;
};

export default SupportLink;
