import ProviderHeadshot from '@/pages/care-team/ProviderHeadshot';
import Text from '@/components/Text';
import { providerLicenses } from '@/utils/constants';
import {
  CareTeamPageState,
  ProviderWithFirstAppointmentInfo,
  TherapyType,
} from '@/utils/types';
import ProviderCardActions from './ProviderCardActions';
import { useContext } from 'react';
import { MobileContext } from '@/App';

interface Props {
  pageState: CareTeamPageState;
  provider: ProviderWithFirstAppointmentInfo;
  active: boolean;
  therapyType: TherapyType;
  canBook?: boolean;
}
export function ProviderLockupV2({
  provider,
  active,
  therapyType,
  canBook = false,
}: Props) {
  const mobile = useContext(MobileContext);
  const providerLicense = providerLicenses[provider.licenses[0]]?.name;
  const mobileHeight = providerLicense ? 'h-20' : 'h-12';
  return (
    <div className="p-4 flex items-center gap-x-6 flex-col md:flex-row gap-1 md:gap-x-6 md:h-[128px]">
      <ProviderHeadshot
        profileImage={provider.profile_image_s3_url}
        className="w-20 h-20"
      />
      <div
        className={`flex items-center ${mobileHeight} flex-row justify-center w-full md:justify-between md:h-20`}
      >
        <div className="flex flex-col md:items-start justify-center overflow-hidden">
          <Text.H3 className="overflow-hidden text-ellipsis line-clamp-1">
            {provider.first_name} {provider.last_name}
          </Text.H3>
          {providerLicense && (
            <Text.P className="text-ellipsis overflow-hidden whitespace-nowrap md:whitespace-normal md:mx-[unset]">
              {providerLicense}
            </Text.P>
          )}
        </div>
        {!mobile ? (
          <ProviderCardActions
            provider={provider}
            therapyType={therapyType}
            active={active}
            canBook={canBook}
          />
        ) : null}
      </div>
    </div>
  );
}
