import { useContext } from 'react';
import { StateContext } from '../App';
import { PageState, ReactChildren } from '../utils/types';

export interface WithPageStateProps<P extends PageState['path']> {
  page: P;
  children: (props: {
    pageState: Extract<PageState, { path: P }>;
  }) => ReactChildren;
}

function WithPageState<P extends PageState['path']>({
  page,
  children,
}: WithPageStateProps<P>) {
  const { state } = useContext(StateContext);
  if (state.ui.page.path !== page) {
    return null;
  }

  return children({
    pageState: state.ui.page as Extract<PageState, { path: P }>,
  });
}

export default WithPageState;
