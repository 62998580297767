import { useContext } from 'react';
import { StateContext } from '../App';
import actions from '../state/actions';
import Text from './Text';

export interface MailToLinkProps {
  email: string;
  text?: string;
  linkColor?: string;
  noUnderline?: boolean;
}

const MailToLink = ({
  email,
  text,
  linkColor = 'text-primary-3',
  noUnderline = true,
}: MailToLinkProps) => {
  const { dispatch } = useContext(StateContext);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        dispatch(
          actions.setToast({
            text: <Text.P>{email} was copied to your clipboard</Text.P>,
            variant: 'success',
            onClose: () => dispatch(actions.setToast(null)),
          })
        );
      })
      .catch(() => {
        dispatch(
          actions.setToast({
            text: <Text.P>Failed to copy email to clipboard: {email}</Text.P>,
            variant: 'warning',
            onClose: () => dispatch(actions.setToast(null)),
          })
        );
      });
  };

  return (
    <a
      role="button"
      className={`${noUnderline ? 'no-underline' : ''} ${linkColor}`}
      onClick={copyToClipboard}
    >
      {text || email}
    </a>
  );
};

export default MailToLink;
