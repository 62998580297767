import React, { useEffect, useRef } from 'react';

// Similar to the componentDidUpdate lifecycle method, this effect is only called after the component has mounted.
const useEffectOnUpdate = (
  fn: () => void,
  deps?: React.DependencyList | undefined,
  cleanup?: () => void
) => {
  const mounting = useRef(true);

  return useEffect(() => {
    if (mounting.current) {
      mounting.current = false;
    } else {
      fn();
    }
    return cleanup;
  }, deps);
};

export default useEffectOnUpdate;
