import { useMemo, useCallback } from 'react';
import {
  ActiveProviderShapeV3,
  InactiveProviderShape,
  TherapyType,
  ProviderWithFirstAppointmentInfo,
} from '@/utils/types';

const useSortedProvidersAndHeadings = (
  providers: (ActiveProviderShapeV3 | InactiveProviderShape)[],
  active: boolean
) => {
  const sortedProviders = useMemo(
    () =>
      [...providers].sort((a, b) =>
        a.first_name.localeCompare(b.first_name, undefined, {
          sensitivity: 'base',
        })
      ),
    [providers]
  );

  const getProvidersAndHeadings = useCallback(() => {
    const providersAndHeadings: {
      heading: TherapyType;
      providers: ProviderWithFirstAppointmentInfo[];
    }[] = [];

    const addProviderToHeading = (
      therapyEvent: {
        therapy_type: TherapyType;
        has_had_appointment?: boolean;
        is_confirmed?: boolean;
      },
      provider: ActiveProviderShapeV3 | InactiveProviderShape
    ) => {
      const providerAndHeading = providersAndHeadings.find(
        (providerAndHeading) =>
          providerAndHeading.heading === therapyEvent.therapy_type
      );

      const providerWithFirstApptInfo: ProviderWithFirstAppointmentInfo = {
        ...provider,
        has_had_appointment: therapyEvent.has_had_appointment,
      };

      if ('is_confirmed' in therapyEvent) {
        providerWithFirstApptInfo.is_confirmed = therapyEvent.is_confirmed;
      }

      if (providerAndHeading) {
        providerAndHeading.providers.push(providerWithFirstApptInfo);
      } else {
        providersAndHeadings.push({
          heading: therapyEvent.therapy_type,
          providers: [providerWithFirstApptInfo],
        });
      }
    };

    sortedProviders.forEach((provider) => {
      const therapyEvents = active
        ? (provider as ActiveProviderShapeV3).active_therapy_types
        : (provider as InactiveProviderShape).deactivation_events;

      therapyEvents.forEach((therapyEvent) => {
        const therapyData: {
          therapy_type: TherapyType;
          has_had_appointment?: boolean;
          is_confirmed?: boolean;
        } = {
          therapy_type: therapyEvent.therapy_type,
        };
        if ('has_had_appointment' in therapyEvent) {
          therapyData.has_had_appointment = therapyEvent.has_had_appointment;
        }

        if ('is_confirmed' in therapyEvent) {
          therapyData.is_confirmed = therapyEvent.is_confirmed;
        }

        addProviderToHeading(therapyData, provider);
      });
    });

    return providersAndHeadings;
  }, [sortedProviders, active]);

  return { providersAndHeadings: getProvidersAndHeadings() };
};

export default useSortedProvidersAndHeadings;
