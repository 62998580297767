import { useContext, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { StateContext } from '@/App';
import RescheduleFeeWarningModal from './RescheduleFeeWarningModal';
import actions from '@/state/actions';
import useData from '@/state/use-data';
import { therapyTypeToVisitType } from '@/utils/constants';
import {
  RescheduleV2Params,
  AnalyticsEvent,
  Trigger,
  JSONValue,
  AppointmentV2,
} from '@/utils/types';
import RedirectToAppointmentsOnError from '@/pages/appointments/RedirectToAppointmentsOnError';
import trackEvent from '@/utils/amplitude';
import {
  getAppointmentLengthAndEndTime,
  appointmentIsWithin24Hours,
  getSlotsInNextSevenDays,
} from '@/utils/dates';
import { getBookableSlotsForProvider } from '@/utils/appointments';
import { getCancellationFee } from '@/utils/billing';
import { getProviderFromCareTeamByNpi } from '@/utils/provider';
import ChooseAppointment from '@/components/appointments/ChooseAppointment';
import RedirectToCareTeamOnErrorV2 from '@/pages/care-team/RedirectToCareTeamOnErrorV2';

const ChooseAppointmentV2 = () => {
  const { providerNpi, appointmentUUID } = useParams<RescheduleV2Params>();

  // Load providers if provider data isn't in state
  const { state, dispatch } = useContext(StateContext);
  const pageState = state.ui.page;

  const { WithData, data } = useData([
    'appointments_v2',
    'careTeam_v3',
    'selfPayRates',
  ]);

  if (pageState.path !== 'appointments') {
    return null;
  }

  return (
    <WithData data={data}>
      {({ appointments_v2, careTeam_v3, selfPayRates }) => {
        const navigate = useNavigate();
        const [searchParams] = useSearchParams();
        // pull appointment out of appointments_v2 with the param appointment_id
        const npi = Number(providerNpi);
        // do we need to account for inactives?
        const provider = getProviderFromCareTeamByNpi(npi, careTeam_v3.active);

        if (!providerNpi || Number.isNaN(npi)) {
          console.error('invalid provider npi');
          return <RedirectToAppointmentsOnError />;
        }

        if (!provider) {
          console.error('cannot locate that provider on active care team');
          return <RedirectToCareTeamOnErrorV2 addCare />;
        }

        if (!appointmentUUID) {
          console.error('no appointment id provided');
          return <RedirectToAppointmentsOnError />;
        }

        const { first_name, last_name } = provider;

        const currentAppointment = appointments_v2.rows.find(
          (appointment) =>
            appointment.ref === `scheduling/appointments/${appointmentUUID}`
        );

        if (!currentAppointment) {
          console.error('unable to locate appointment');
          return <RedirectToAppointmentsOnError />;
        }

        const isDeeplink = !!searchParams.get('deeplink');
        const {
          end_time,
          start_time,
          therapy_type: therapyType,
          series_type,
          status,
        } = currentAppointment as AppointmentV2;
        const isFirstAppointment = series_type === 'initial';

        const isHold = status === 'held' || status === 'deleted';

        const bookableSlots = getBookableSlotsForProvider(
          state.ui.usedAvailabilitySlots,
          provider,
          currentAppointment.therapy_type,
          currentAppointment.series_type,
          isHold
        );

        const hasBookableAppointments =
          bookableSlots && bookableSlots.length > 0;

        const appointmentType =
          therapyTypeToVisitType[therapyType] ||
          therapyTypeToVisitType.individual;

        const { appointmentLengthInMinutes } = getAppointmentLengthAndEndTime(
          start_time,
          end_time
        );

        const appointmentInNextDay = appointmentIsWithin24Hours(start_time);

        const trackRescheduleEvent = (
          name: string,
          trigger: Trigger,
          metadata: { [key: string]: JSONValue } = {}
        ) => {
          const event: AnalyticsEvent = {
            product_area: 'Appointments',
            name,
            trigger,
            metadata: {
              appointment_id: appointmentUUID,
              appointment_type: appointmentType,
              appointment_status: status === 'held' ? 'hold' : 'appointment',
              appointment_duration_mins: appointmentLengthInMinutes,
              provider_name: `${first_name} ${last_name}`,
              provider_npi: providerNpi,
              treatment_type:
                therapyType === 'psychiatric' ? 'psychiatry' : 'therapy',
              within_late_cancel_window: appointmentInNextDay,
              ...metadata,
            },
          };
          trackEvent(event);
        };

        useEffect(() => {
          const sessionsWithin7Days = getSlotsInNextSevenDays(bookableSlots);
          trackRescheduleEvent(
            'choose_new_appointment_page_view',
            'Page load',
            {
              patient_app_linked_from_email: isDeeplink,
              available_sessions_count: bookableSlots.length,
              available_sessions_within_7_days_count: sessionsWithin7Days,
            }
          );
          const isCanceledOrDeletedAppointment =
            status === 'canceled' || status === 'deleted';
          if (isDeeplink && appointmentInNextDay) {
            if (isCanceledOrDeletedAppointment) return;
            trackAndShowRescheduleModal();
          }
        }, []);

        const trackAndShowRescheduleModal = () => {
          const cancellationFee = getCancellationFee(provider, selfPayRates);
          trackRescheduleEvent(
            'last_minute_reschedule_modal_viewed',
            'Deeplink'
          );
          dispatch(
            actions.setModal({
              children: (
                <RescheduleFeeWarningModal
                  dispatch={dispatch}
                  cancellationFee={cancellationFee}
                  onCancel={() => navigate('/')}
                />
              ),
            })
          );
        };

        const oldAppointmentProps = {
          isFirstAppointment,
          startTime: start_time,
          appointmentType,
          previousLengthInMinutes: appointmentLengthInMinutes,
        };

        return (
          <ChooseAppointment
            provider={provider}
            hasBookableAppointments={hasBookableAppointments}
            therapyType={therapyType}
            seriesType={series_type}
            appointmentLengthInMinutes={appointmentLengthInMinutes}
            oldAppointmentProps={oldAppointmentProps}
            isHold={isHold}
          />
        );
      }}
    </WithData>
  );
};

export default ChooseAppointmentV2;
