import Button from '../../components/Button';
import { useContext } from 'react';
import { StateContext } from '../../App';
import actions from '../../state/actions';
import SeeAllSpecialtiesModal from './SeeAllSpecialtiesModal';
import { ProviderDetail } from '../../utils/types';

const SeeAllSpecialtiesButton = ({
  provider,
}: {
  provider: ProviderDetail;
}) => {
  const { dispatch } = useContext(StateContext);

  const closeModal = () => dispatch(actions.setModal(null));
  const modal = (
    <SeeAllSpecialtiesModal onClose={closeModal} provider={provider} />
  );
  const onClick = () => {
    dispatch(actions.setModal({ children: modal }));
  };
  return (
    <Button sizeClasses="w-auto" onClick={onClick} variant="primary-outline">
      See all specialties
    </Button>
  );
};

export default SeeAllSpecialtiesButton;
