import React, { useContext, useState } from 'react';
import { MobileContext } from '@/App';
import InfoCircle from '@/components/icons/InfoCircle';
import stopPropagation from '@/utils/stop-propagation';
import InfoCard from '@/components/InfoCard';
import Text from '@/components/Text';

const Tooltip = ({ info }: { info: string }) => {
  const mobile = useContext(MobileContext);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <div className="relative w-full">
      <InfoCircle.Small
        role="img"
        className="inline-block"
        onClick={stopPropagation((e) => {
          e.preventDefault();
          setTooltipOpen(!tooltipOpen);
        })}
        onMouseEnter={mobile ? undefined : () => setTooltipOpen(true)}
        onMouseLeave={mobile ? undefined : () => setTooltipOpen(false)}
      />

      {tooltipOpen && (
        <div className="flex-grow z-10 absolute top-0 left-[calc(5%)] md:max-w-[356px] md:w-max max-w-prose items-center">
          <InfoCard>
            <Text.P>{info}</Text.P>
          </InfoCard>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
