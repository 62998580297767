import Icon, { IconProps } from './Icon';
import theme from '@/utils/theme';

const InfoCircle = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={props.stroke || theme.colors['tertiary-7']}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export const SmallInfoCircle = (props: IconProps) => {
  return (
    <Icon.Small {...props}>
      <path
        d="M8 10.8V8M8 5.2H8.007M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(2, 2)"
      />
    </Icon.Small>
  );
};

InfoCircle.Small = SmallInfoCircle;

export default InfoCircle;
