import dayjs, { Dayjs } from 'dayjs';
import { ComponentProps, useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { MobileContext, StateContext } from '@/App';
import Chevron from '@/components/icons/Chevron';
import Text from '@/components/Text';
import { getSlotsByDay } from '@/utils/dates';
import { ProviderDetail, TherapyType, SeriesType } from '@/utils/types';
import ReplaceProviderTriggerV2 from './ReplaceProviderTriggerV2';
import { getBookableSlotsForProvider } from '@/utils/appointments';
import trackEvent from '@/utils/amplitude';

type GenerateLinkProps = (
  newAppointment: string
) => ComponentProps<typeof Link>;

interface AppointmentPickerProps {
  provider: ProviderDetail;
  generateLinkProps: GenerateLinkProps;
  therapyType: TherapyType;
  seriesType?: SeriesType;
  isHold?: boolean;
}

export const SlotTimeDiv = ({
  generateLinkProps,
  newAppointment,
}: {
  generateLinkProps: GenerateLinkProps;
  newAppointment: Dayjs;
}) => {
  return (
    <Link
      className="block rounded-2 w-[87px] py-[0.375rem] bg-primary-3 text-tertiary-0 text-center no-underline"
      {...generateLinkProps(newAppointment.unix().toString(10))}
    >
      <Text.Small.Bold className="align-text-bottom">
        {newAppointment.format('h:mm A')}
      </Text.Small.Bold>
    </Link>
  );
};

const AppointmentPicker = ({
  generateLinkProps,
  provider,
  therapyType,
  seriesType = 'initial',
  isHold,
}: AppointmentPickerProps) => {
  const mobile = useContext(MobileContext);
  const { state } = useContext(StateContext);
  const bookableSlots = getBookableSlotsForProvider(
    state.ui.usedAvailabilitySlots,
    provider,
    therapyType,
    seriesType,
    isHold
  );
  const slotsByDay = getSlotsByDay(bookableSlots);
  const days: Dayjs[] = [];
  let firstDateWithSlot = dayjs().subtract(1, 'year');
  slotsByDay.forEach((value) => {
    days.push(value.date);
    // Select the first day with any appointments
    if (
      value.slots &&
      value.slots?.length > 0 &&
      firstDateWithSlot.isBefore(dayjs())
    ) {
      firstDateWithSlot = value.date;
    }
  });

  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const [firstDateIndexInViewer, setFirstDateIndexInViewer] = useState(0);
  const [selectedDate, setSelectedDate] = useState(days[selectedDateIndex]);

  // TODO - daysToShow and scrollDistance are a little awkward on small screens
  const daysToShow = mobile ? 1 : 4;
  const scrollDistance = mobile ? 1 : 3;

  const dateBoxWidth = 7.75; //in rem
  // TODO - make sizing dynamic so this works for both the reschedule and rematch workflows
  //const dateBoxWidthString = `w-[${dateBoxWidth}rem]`;
  const dateBoxGap = 0.75; //in rem
  const scrollButtonWidth = 5; //in rem

  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const slidingBoxRef = useRef<HTMLDivElement>(null);

  const chooseDate = (selectedDay: Dayjs) => {
    setSelectedDate(selectedDay);
    const dateIndex = days.findIndex((day) => day == selectedDay);
    setSelectedDateIndex(dateIndex);
    setFirstDateIndexInViewer(dateIndex);
    scrollTo(dateIndex);
  };

  const findFirstDateToShow = (index: number) => {
    const totalDays = days.length;
    if (index >= totalDays - daysToShow) {
      return totalDays - daysToShow;
    } else {
      return index;
    }
  };

  const scrollLeft = () => {
    const newFirstDateIndex =
      firstDateIndexInViewer >= scrollDistance
        ? firstDateIndexInViewer - scrollDistance
        : 0;
    setFirstDateIndexInViewer(newFirstDateIndex);
    scrollTo(newFirstDateIndex);
  };

  const scrollRight = () => {
    const newFirstDateIndex =
      firstDateIndexInViewer < days.length - scrollDistance
        ? firstDateIndexInViewer + scrollDistance
        : days.length - scrollDistance;
    setFirstDateIndexInViewer(newFirstDateIndex);
    scrollTo(newFirstDateIndex);
  };

  const scrollTo = (index: number) => {
    index = findFirstDateToShow(index);
    const offset =
      index == 0 ? 0 : index * (dateBoxWidth + dateBoxGap) - scrollButtonWidth;

    slidingBoxRef.current?.style.setProperty(
      'transform',
      `translateX(-${offset}rem)`
    );

    if (index == 0) {
      // show 'next' button but not 'previous'
      prevRef.current?.style.setProperty('visibility', 'hidden');
      nextRef.current?.style.removeProperty('visibility');
    } else if (index >= days.length - daysToShow) {
      // show 'previous' button but not 'next'
      nextRef.current?.style.setProperty('visibility', 'hidden');
      prevRef.current?.style.removeProperty('visibility');
    } else {
      //show both buttons
      nextRef.current?.style.removeProperty('visibility');
      prevRef.current?.style.removeProperty('visibility');
    }
  };

  const slotsDayButton = (day: dayjs.Dayjs) => {
    const slotsForDay = slotsByDay.get(day.date())?.slots?.length || 0;
    return (
      <div
        className={`flex-grow-0 flex-shrink-0 flex flex-col border rounded-2 cursor-pointer
          ${
            selectedDate.date() == day.date()
              ? 'border-primary-3'
              : 'border-tertiary-2'
          }
          p-2 h-[4.5rem] w-[7.75rem] text-center inline-block`}
        data-calendar-day={day.format('MMM D')}
        onClick={() => chooseDate(day)}
        key={day.unix()}
      >
        <Text.Small className="text-tertiary-5">
          {day.format('ddd').toUpperCase()}
        </Text.Small>
        <Text.Small.Bold className=" text-tertiary-5">
          {day.format('MMM D')}
        </Text.Small.Bold>
        <div
          className={`text-xs ${
            slotsForDay > 0 ? 'text-primary-3' : 'text-tertiary-4'
          }`}
        >
          {slotsForDay} slot{slotsForDay == 1 ? '' : 's'}
        </div>
      </div>
    );
  };

  const noAvailabilityonSelectedDay =
    !slotsByDay.get(selectedDate.date())?.slots?.length &&
    bookableSlots.length > 0;

  const providerHasAvailability =
    bookableSlots &&
    bookableSlots.length > 0 &&
    (firstDateWithSlot.isAfter(dayjs()) || firstDateWithSlot.isSame(dayjs()));

  const soonestAvailabilityComponent = (
    <p>
      No slots on {selectedDate.format('dddd, MMM. D')}. The soonest day with
      availability is{' '}
      <span
        className="text-primary-3 hover:text-primary-4 cursor-pointer"
        onClick={() => chooseDate(firstDateWithSlot)}
      >
        {firstDateWithSlot.format('dddd, MMM. D')}
      </span>
    </p>
  );

  const trackSlotTimeClick = (time: Dayjs) => {
    trackEvent({
      product_area: 'Appointments',
      name: 'appointment_time_selected',
      trigger: 'Interaction',
      metadata: {
        therapy_type: therapyType,
        provider_npi: provider.npi,
        time_slot_of_appointment: time.utc().format(),
      },
    });
  };

  return (
    <div>
      {/* Dates */}
      {/* TODO - do the dates and/or times need a hover state? */}
      {providerHasAvailability ? (
        <div>
          <div className="border-b border-tertiary-2 align-middle relative py-4">
            <button
              className={`absolute top-0 w-20 h-full z-[9] ${
                firstDateIndexInViewer == 0 ? 'invisible' : ''
              } bg-gradient-to-r from-white via-white`}
              ref={prevRef}
              onClick={() => scrollLeft()}
            >
              <Chevron
                size={18}
                stroke="tertiary-6"
                className="h-6 w-6 rounded-circle border border-tertiary-2 my-[0.125rem] mx-auto rotate-90"
              />
            </button>
            <div className="overflow-hidden w-full h-20">
              <div
                className={`flex gap-x-3 mx-3 ml-0 transition-transform duration-300`}
                ref={slidingBoxRef}
              >
                {days.map((day) => slotsDayButton(day))}
              </div>
            </div>
            <button
              className="absolute w-20 h-full right-0 top-0 bg-gradient-to-l z-[9] from-white via-white"
              ref={nextRef}
              onClick={() => scrollRight()}
            >
              <Chevron
                size={18}
                stroke="tertiary-6"
                className="h-6 w-6 rounded-circle border border-tertiary-2 my-[0.125rem] mx-auto -rotate-90"
              />
            </button>
          </div>

          {/* Times */}
          {!noAvailabilityonSelectedDay && (
            <div>
              <p className="mt-4">
                All times are shown in {dayjs().local().format('zzz (z)')}
              </p>
            </div>
          )}
          <div className="my-4">
            {!!slotsByDay.get(selectedDate.date())?.GroupedSlotsForDay?.Morning
              .length && (
              <div>
                <Text.P.Bold className="mb-2 mt-4">Morning</Text.P.Bold>
                <div className="w-full flex flex-row flex-wrap gap-2">
                  {slotsByDay
                    .get(selectedDate.date())
                    ?.GroupedSlotsForDay?.Morning.map((availableTime) => (
                      <div onClick={() => trackSlotTimeClick(availableTime)}>
                        <SlotTimeDiv
                          generateLinkProps={generateLinkProps}
                          newAppointment={availableTime}
                          key={availableTime.unix()}
                        />
                      </div>
                    ))}
                </div>
              </div>
            )}
            {!!slotsByDay.get(selectedDate.date())?.GroupedSlotsForDay
              ?.Afternoon.length && (
              <div>
                <Text.P.Bold className="mb-2 mt-4">Afternoon</Text.P.Bold>
                <div className="w-full flex flex-row flex-wrap gap-2">
                  {slotsByDay
                    .get(selectedDate.date())
                    ?.GroupedSlotsForDay?.Afternoon.map((availableTime) => (
                      <div onClick={() => trackSlotTimeClick(availableTime)}>
                        <SlotTimeDiv
                          generateLinkProps={generateLinkProps}
                          newAppointment={availableTime}
                          key={availableTime.unix()}
                        />
                      </div>
                    ))}
                </div>
              </div>
            )}
            {!!slotsByDay.get(selectedDate.date())?.GroupedSlotsForDay?.Evening
              .length && (
              <div>
                <Text.P.Bold className="mb-2 mt-4">Evening</Text.P.Bold>
                <div className="w-full flex flex-row flex-wrap gap-2">
                  {slotsByDay
                    .get(selectedDate.date())
                    ?.GroupedSlotsForDay?.Evening.map(
                      (availableTime, index) => (
                        <div onClick={() => trackSlotTimeClick(availableTime)}>
                          <SlotTimeDiv
                            generateLinkProps={generateLinkProps}
                            newAppointment={availableTime}
                            key={index}
                          />
                        </div>
                      )
                    )}
                </div>
              </div>
            )}
            {noAvailabilityonSelectedDay &&
              firstDateWithSlot.isAfter(dayjs()) &&
              soonestAvailabilityComponent}
          </div>
        </div>
      ) : (
        <div>
          <p className="pt-8">
            This provider has no upcoming availability.{' '}
            <ReplaceProviderTriggerV2
              providerNpi={provider.npi}
              therapyType={therapyType}
            >
              {({ onClick }) => (
                <span
                  className="text-primary-3 hover:text-primary-4 cursor-pointer"
                  onClick={onClick}
                >
                  Find a new provider?
                </span>
              )}
            </ReplaceProviderTriggerV2>
          </p>
        </div>
      )}
    </div>
  );
};

export default AppointmentPicker;
