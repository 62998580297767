import { therapyTypeToInitialAppointmentDescription } from '@/utils/appointments';
import { TherapyType } from '@/utils/types';
import Text from '@/components/Text';
import User from '@/components/icons/User';
import TwoPeople from '@/components/icons/TwoPeople';

const TherapyTypeText = ({
  therapyType,
  isFirstAppointment,
  appointmentType,
}: {
  therapyType: TherapyType;
  isFirstAppointment: boolean;
  appointmentType: string;
}) => (
  <>
    {therapyType === 'individual' ? (
      <User className="grow-0 shrink-0" />
    ) : (
      <TwoPeople className="grow-0 shrink-0" />
    )}
    <Text.P>
      {isFirstAppointment && therapyType
        ? therapyTypeToInitialAppointmentDescription(therapyType)
        : appointmentType}
    </Text.P>
  </>
);

export default TherapyTypeText;
