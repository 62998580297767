const Calendar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="56"
      viewBox="0 0 80 56"
      fill="none"
    >
      <rect x="7.04785" width="72.9515" height="55.2076" fill="#303030" />
      <path d="M7.04846 0H80L72.9515 55.2076H0L7.04846 0Z" fill="#A5D1C2" />
      <path
        d="M8.67365 48.7304L14.1346 6.12695H70.9734L65.5125 48.7304H8.67365Z"
        fill="#A5D1C2"
        stroke="black"
      />
      <path
        d="M15.5255 18.6377H30.3086L28.8803 29.8902H14.0972L15.5255 18.6377Z"
        fill="#00634B"
      />
      <path
        d="M17.4449 20.3955L22.1145 24.2636M26.7841 28.1316L22.1145 24.2636M22.1145 24.2636L16.2114 28.1316M22.1145 24.2636L28.1938 20.3955"
        stroke="#A5D1C2"
      />
      <path
        d="M32.2853 29.3902L33.5866 19.1377H47.3617L46.0604 29.3902H32.2853Z"
        fill="#A5D1C2"
        stroke="black"
      />
      <path
        d="M49.9068 29.3902L51.2082 19.1377H64.9833L63.6819 29.3902H49.9068Z"
        fill="#A5D1C2"
        stroke="black"
      />
      <path
        d="M12.9015 43.1041L14.2029 32.8516H27.978L26.6766 43.1041H12.9015Z"
        fill="#A5D1C2"
        stroke="black"
      />
      <path
        d="M30.3561 43.1265L31.6574 32.874H45.4325L44.1312 43.1265H30.3561Z"
        fill="#A5D1C2"
        stroke="black"
      />
      <path
        d="M48.1446 43.1041L49.446 32.8516H63.2211L61.9197 43.1041H48.1446Z"
        fill="#A5D1C2"
        stroke="black"
      />
      <path
        d="M16.501 13.6927C22.5899 10.8025 28.3329 10.034 29.7859 12.0318C31.4463 14.3147 29.1632 15.9678 33.3147 14.5227C36.0382 13.5747 41.2026 9.95605 49.7133 9.95605C57.6012 9.95605 62.1679 13.0697 65.074 14.7383"
        stroke="#303030"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Calendar;
