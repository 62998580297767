import { useState } from 'react';
import { makeCorrectCasing } from '@/utils/format';
import Text from '@/components/Text';
import RadioButton from '@/components/RadioButton';
import ModalWrapper from '@/components/ModalWrapper';
import Tooltip from '@/components/Tooltip';

export interface PreferenceModalProps {
  data: Record<string, string[]>;
  heading: string;
  onCheck: (preference: string | undefined) => unknown;
  selected: string | undefined;
  categoryTooltips?: { [key: string]: string };
}

const PreferenceModal = ({
  data,
  heading,
  onCheck,
  selected,
  categoryTooltips,
}: PreferenceModalProps) => {
  const [selectedPreference, setSelectedPreference] = useState(selected);
  const save = () => onCheck(selectedPreference);
  const categories = Object.keys(data);

  return (
    <ModalWrapper
      heading={heading}
      onSave={save}
      onClear={() => setSelectedPreference(undefined)}
    >
      {categories.map((category, i) => (
        <div key={category}>
          <div className="flex mb-3">
            <Text.P.Bold>{category}</Text.P.Bold>
            {categoryTooltips && categoryTooltips[category] && (
              <div className="pl-1 grow">
                <Tooltip info={categoryTooltips[category]} />
              </div>
            )}
          </div>
          <div className="max-h-[465px] columns-2 space-y-2 mb-5">
            {data[category].map((preference) => (
              <RadioButton
                key={preference}
                label={makeCorrectCasing(preference)}
                onCheck={() => setSelectedPreference(preference)}
                name="preference"
                selectedValue={selectedPreference ?? ''}
                value={preference}
              />
            ))}
          </div>
          {i !== categories.length - 1 ? (
            <div className="border-b-1 mt-5 mb-5 border-tertiary-2"></div>
          ) : null}
        </div>
      ))}
    </ModalWrapper>
  );
};

export default PreferenceModal;
