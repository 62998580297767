import { useContext } from 'react';

import Loader from '@/components/Loader';
import { MobileContext } from '@/App';

const MessagingLoader = () => {
  const mobile = useContext(MobileContext);

  if (mobile) {
    return <Loader.Contained />;
  }

  return (
    <Loader.Container>
      <Loader className="w-12 h-12" />
    </Loader.Container>
  );
};

export default MessagingLoader;
