import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import User from './components/icons/User';
import CreditCard from './components/icons/CreditCard';
import NavItem from './components/NavItem';
import NavMenu from './components/NavMenu';
import { StateContext } from './App';
import actions from './state/actions';
import { PageState } from './utils/types';
import TwoPeople from './components/icons/TwoPeople';
import AppointmentCalendar from './components/icons/AppointmentCalendar';
import Email from './components/icons/Email';
import { Badge } from '@pathccm/path-components-messaging';
import useData from './state/use-data';
import { getProviderFromCareTeam } from './utils/tools';

const pathToPage: () => Array<[RegExp, PageState['path']]> = () => [
  [/\/appointments/, 'appointments'],
  [/\/messages/, 'messages'],
  [/\/profile/, 'profile'],
  [/\/billing/, 'billing'],
  [/\/care-team/, 'care-team'],
  [/\//, 'appointments'],
];

const Nav = ({ onClick = () => {} }: { onClick?: () => unknown }) => {
  const { state, dispatch } = useContext(StateContext);
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    // keep page state in sync with location
    const match = pathToPage().find((entry) =>
      entry[0].test(location.pathname)
    );
    if (match && match[1] !== state.ui.page.path) {
      dispatch(actions.setPage(match[1]));
    }

    // close any modals when location changes
    dispatch(actions.setModal(null));
  }, [location.pathname]);

  const { WithData, data } = useData(['messaging', 'careTeam_v3']);
  return (
    <WithData data={data}>
      {({ messaging, careTeam_v3: careTeam }) => {
        const { threads = [] } = messaging;
        const threadsWithProviderOnCareTeam = threads.filter((thread) => {
          return !!getProviderFromCareTeam(careTeam, thread.providerUuid);
        });
        const threadCount = threadsWithProviderOnCareTeam.reduce(
          (totalUnreads, { unreadCount }) => {
            return totalUnreads + unreadCount;
          },
          0
        );
        return (
          <NavMenu>
            <NavItem
              label="Appointments"
              Icon={AppointmentCalendar}
              active={
                location.pathname.startsWith('/appointments') ||
                location.pathname === '/'
              }
              onClick={() => {
                navigate('/appointments');
                onClick();
              }}
            />
            <NavItem
              label="Messages"
              Icon={Email}
              active={location.pathname.startsWith('/messages')}
              onClick={() => {
                navigate('/messages');
                onClick();
              }}
              badge={threadCount > 0 ? <Badge count={threadCount} /> : null}
            />
            <NavItem
              label="Care team"
              Icon={TwoPeople}
              active={location.pathname.startsWith('/care-team')}
              onClick={() => {
                navigate('/care-team');
                onClick();
              }}
            />
            <NavItem
              label="Profile"
              Icon={User}
              active={location.pathname.startsWith('/profile')}
              onClick={() => {
                navigate('/profile');
                onClick();
              }}
            />
            <NavItem
              label="Billing"
              Icon={CreditCard}
              active={location.pathname.startsWith('/billing')}
              onClick={() => {
                navigate('/billing');
                onClick();
              }}
            />
          </NavMenu>
        );
      }}
    </WithData>
  );
};

export default Nav;
