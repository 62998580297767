import { useContext, useCallback } from 'react';
import { useSession, useSetSession, useTimeout } from '../../utils/session';
import Button from '../../components/Button';
import X from '../../components/icons/X';
import Text from '../../components/Text';
import { StateContext } from '../../App';
import actions from '../../state/actions';
import fetch from '@/utils/fetch';

export type SessionExpiryModalProps = {
  onClose: () => unknown;
  title: string | undefined;
  actionText: string;
};

const SessionExpiryModal = ({
  onClose,
  title,
  actionText,
}: SessionExpiryModalProps) => {
  const { dispatch } = useContext(StateContext);
  const { timeLeftText } = useSession();
  const logout = useTimeout(dispatch);
  const setSession = useSetSession(dispatch);

  const onRefresh = useCallback(() => {
    fetch('/session/refresh', { method: 'POST' })
      .then(() => {
        setSession();
        dispatch(actions.setModal(null));
      })
      .catch(logout);
  }, []);

  return (
    <div className="rounded-2 p-6 space-y-6 bg-tertiary-0 md:w-[520px]">
      <div className="flex justify-between">
        <Text.H4>{title}</Text.H4>
        <X onClick={onClose} className="cursor-pointer"></X>
      </div>

      <div className="space-y-3">
        <Text.Small className="text-tertiary-7">
          You will be logged out in {timeLeftText}.
        </Text.Small>
      </div>

      <div className="space-y-3">
        <div className="flex column space-x-3">
          <Button
            onClick={logout}
            size={'small'}
            sizeClasses="w-full"
            variant="primary-outline"
          >
            {`Log out`}
          </Button>
          <Button
            onClick={onRefresh}
            size={'small'}
            sizeClasses="w-full"
            variant="primary"
          >
            {actionText}
          </Button>
        </div>
      </div>
    </div>
  );
};

SessionExpiryModal.defaultProps = {
  title: 'Session expiration',
  actionText: 'Stay signed in',
};

export default SessionExpiryModal;
