import { useNavigate } from 'react-router-dom';
import { ReactChildren, TherapyType } from '../../utils/types';
export interface ReplaceProviderTriggerV3Props {
  providerNpi: number;
  children: (props: { onClick: () => unknown }) => ReactChildren;
  therapyType: TherapyType;
}

const ReplaceProviderTriggerV3 = ({
  children,
  providerNpi,
  therapyType,
}: ReplaceProviderTriggerV3Props) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(
      `/care-team/${providerNpi}/${therapyType}/replace/find-new-provider`
    );
  };
  return children({ onClick });
};

export default ReplaceProviderTriggerV3;
