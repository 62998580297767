import { storageAvailable } from './tools';

export default <T>(f: () => T, dflt: T) => {
  try {
    return f();
  } catch (e) {
    if (
      storageAvailable('localStorage') &&
      localStorage?.getItem('log') === 'true'
    ) {
      console.error(e);
    }
    return dflt;
  }
};
