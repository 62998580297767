import 'react';
import { TherapyType } from '@/utils/types';
import Text from '@/components/Text';

const HEALTH_INSURANCE = 'Health Insurance';

const PaymentText = ({
  paymentMethod,
  therapyType,
  cashPayAmount,
  insurances,
  networkName,
  carrier,
}: {
  paymentMethod: string;
  therapyType: TherapyType | '';
  cashPayAmount: string;
  insurances: string[];
  networkName?: string;
  carrier?: string;
}) => {
  if (
    paymentMethod === HEALTH_INSURANCE &&
    networkName &&
    insurances.includes(networkName.toLowerCase())
  ) {
    return (
      <div>
        <Text.P>Covered by {carrier}</Text.P>
        <Text.Small>
          Session cost depends on your insurance. You won’t be charged until
          after your first appointment.
        </Text.Small>
      </div>
    );
  } else if (therapyType) {
    if (paymentMethod === HEALTH_INSURANCE) {
      return (
        <div>
          <Text.P>
            The session will cost {cashPayAmount} since this provider doesn't
            accept your insurance.
          </Text.P>
        </div>
      );
    } else {
      return (
        <div>
          <Text.P>The session will cost {cashPayAmount}.</Text.P>
        </div>
      );
    }
  } else {
    return (
      <div>
        <Text.P>
          Session cost will be shown once a therapy type has been selected.
        </Text.P>
      </div>
    );
  }
};

export default PaymentText;
