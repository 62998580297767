import fetch from '@/utils/fetch';
import { Reducer, useLayoutEffect, useReducer, useRef } from 'react';
import { ProviderDetail } from '@/utils/types';

type Status = 'loading' | 'error' | 'done' | 'skipped';
type NotDone = Exclude<Status, 'done'>;
type StatusTuple = [ProviderDetail, 'done'] | [null, Exclude<Status, 'done'>];
type Action = NotDone | [ProviderDetail, 'done'];
type Props = {
  providerNpi: string;
  patientRecordUuid: string;
  skip: boolean;
};

const reducer = (_state: StatusTuple, action: Action): StatusTuple =>
  typeof action === 'string' ? [null, action] : action;

export function useProvider({
  providerNpi,
  patientRecordUuid,
  skip,
}: Props): StatusTuple {
  const cancelRef = useRef(false);
  const firstTimeRef = useRef(true);
  const [state, dispatch] = useReducer<Reducer<StatusTuple, Action>>(reducer, [
    null,
    skip ? 'loading' : 'skipped',
  ]);

  useLayoutEffect(() => {
    if (skip || state[1] === 'done') {
      return;
    } else if (!skip && state[1] === 'skipped') {
      dispatch('loading');
    }

    fetch
      .json(
        `/api/provider?${new URLSearchParams({
          patient_record_uuid: patientRecordUuid,
          provider_npi: providerNpi,
        })}`,
        {
          method: 'GET',
        }
      )
      .then((result) => {
        if (cancelRef.current) return;
        dispatch([result, 'done']);
      })
      .catch(() => {
        if (cancelRef.current) return;
        dispatch('error');
      });

    return () => {
      cancelRef.current = true;
    };
  }, [skip]);
  firstTimeRef.current = false;

  return state;
}
