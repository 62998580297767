import Icon, { IconProps } from './Icon';

const Education = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        d="M16.8572 10.9592C16.9091 11.0122 16.9485 11.076 16.9727 11.146C17 11.2253 17 11.315 17 11.4945V14.5001L16.8572 10.9592ZM16.8572 10.9592C16.7986 10.8993 16.7183 10.8592 16.5578 10.779L12 8.50006L16.8572 10.9592ZM11.9078 13.4325C11.9687 13.4439 12.0313 13.4439 12.0922 13.4325C12.161 13.4196 12.2266 13.3868 12.3578 13.3212L22 8.50006L12.3578 3.67895C12.2266 3.61336 12.161 3.58056 12.0922 3.56766C12.0313 3.55622 11.9687 3.55622 11.9078 3.56766C11.839 3.58056 11.7734 3.61336 11.6422 3.67895L2 8.50006L11.6422 13.3212C11.7734 13.3868 11.839 13.4196 11.9078 13.4325ZM11.9078 13.4325L20 9.50006V16.3067C20 16.6786 20 16.8645 19.942 17.0274C19.8907 17.1713 19.8071 17.3016 19.6976 17.4082C19.5738 17.5287 19.4047 17.6062 19.0666 17.7612L12.6666 20.6945C12.4214 20.8069 12.2988 20.8631 12.1711 20.8853C12.0579 20.9049 11.9421 20.9049 11.8289 20.8853C11.7012 20.8631 11.5786 20.8069 11.3334 20.6945L4.93335 17.7612C4.59527 17.6062 4.42622 17.5287 4.30238 17.4082C4.1929 17.3016 4.10931 17.1713 4.05802 17.0274C4 16.8645 4 16.6786 4 16.3067V9.50006L11.9078 13.4325Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default Education;
