import { useContext } from 'react';
import { MobileContext } from '@/App';
import Text from '@/components/Text';
import SupportLink from './SupportLink';

const MaintenanceBanner = () => {
  const mobile = useContext(MobileContext);
  const TextComponent = mobile ? Text.Small : Text.P;
  return (
    <TextComponent className="text-center text-warning-1 p-1">
      The patient portal is undergoing maintenance. Some functionality may not
      currently be available. If you are experiencing issues, please try again
      later or contact <SupportLink />.
    </TextComponent>
  );
};
export default MaintenanceBanner;
