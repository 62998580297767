import Text from '@/components/Text';
import { Loaded, Billing as BillingData } from '@/utils/types';
import ChargeRow from './ChargeRow';

interface BillingStatementsProps {
  billing: Loaded<BillingData>;
}

const ChargesTab = ({ billing }: BillingStatementsProps) => {
  return (
    <>
      <Text.H3 className="text-tertiary-7 mt-5">Charges</Text.H3>
      <Text.P className="mb-5 text-tertiary-5 mt-2">
        All credit card charges will appear here. If you need a receipt for
        reimbursement, please download a statement.
      </Text.P>
      <div className="pt-3 pb-3 w-full flex justify-between border-b-1 border-tertiary-2">
        <div className="w-full flex justify-between items-center">
          <Text.P.Bold className="flex-1">Date</Text.P.Bold>
          <Text.P.Bold className="flex-1 hidden md:block">Amount</Text.P.Bold>
          <Text.P.Bold className="flex-1 hidden md:block">
            Payment method
          </Text.P.Bold>
        </div>
      </div>
      {billing.payments.map(
        (payment: {
          date_of_payment: string;
          payment_amount: string;
          external_id: number;
        }) => (
          <ChargeRow key={payment.external_id} payment={payment} />
        )
      )}
    </>
  );
};

export default ChargesTab;
