const RulaLogo = () => {
  return (
    <svg
      width="100"
      height="35"
      viewBox="0 0 100 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4024 8.06628C17.7384 5.4128 13.4193 5.4128 10.7554 8.06628C8.40399 10.4084 8.1289 14.0324 9.92634 16.6762L9.92743 16.6778L13.3057 21.6142C13.4039 21.7577 13.6097 21.7752 13.7308 21.6502L15.0533 20.2861C15.1712 20.1645 15.1871 19.9772 15.0916 19.8375L11.98 15.2909L11.9791 15.2896C10.8369 13.6081 11.0148 11.3018 12.5092 9.81323C14.2045 8.12456 16.9532 8.12456 18.6485 9.81323C19.8363 10.9963 20.1919 12.6944 19.7154 14.186C19.453 15.0072 19.0906 15.8556 18.8572 16.3736C18.7818 16.5409 18.975 16.7127 19.1356 16.6243C19.9044 16.2013 20.7238 15.9025 21.5638 15.7283C21.7048 15.699 21.8268 15.6064 21.8825 15.4723C22.9058 13.0094 22.4125 10.0685 20.4024 8.06628ZM7.95391 17.4122C6.16431 17.3778 4.36362 18.0408 2.99796 19.4011C0.334012 22.0545 0.334012 26.3567 2.99796 29.0102C5.66192 31.6637 9.98104 31.6637 12.645 29.0102L12.6525 29.0027L12.6528 29.0024L20.274 21.1415C21.9699 19.4599 24.7134 19.4621 26.4064 21.1485C28.1017 22.8371 28.1017 25.575 26.4064 27.2637C24.711 28.9523 21.9623 28.9523 20.267 27.2637C19.7728 26.7714 19.415 26.1558 19.0235 25.5836C18.9253 25.4401 18.7196 25.4226 18.5985 25.5476L17.2759 26.9117C17.158 27.0333 17.1421 27.2206 17.2376 27.3603C17.6327 27.9376 18.0144 28.5138 18.5132 29.0106C21.1771 31.6641 25.4962 31.6641 28.1602 29.0106C30.8242 26.3571 30.8242 22.055 28.1602 19.4015C25.5454 16.797 21.1131 16.8159 18.5134 19.3993L18.5131 19.3997L10.8841 27.2702C9.18822 28.9517 6.44474 28.9495 4.7518 27.2632C3.05647 25.5746 3.05647 22.8367 4.7518 21.148C5.99299 19.9117 7.77969 19.7616 9.44405 19.7595C9.59242 19.7594 9.68329 19.5913 9.59819 19.469L8.29558 17.5977C8.21762 17.4857 8.09155 17.4149 7.95391 17.4122Z"
        fill="#354535"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.7521 11.5977C86.3686 11.5977 84.2084 12.4966 82.646 14.1845C81.0843 15.8716 80.1387 18.326 80.1387 21.4074C80.1387 23.8258 80.7961 26.2213 82.2519 28.0186C83.715 29.825 85.9634 31 89.079 31C89.8816 31 91.2552 30.8109 92.5976 29.9935C93.247 29.5981 93.8844 29.0583 94.4441 28.3292C94.6017 28.1239 94.9939 28.2566 94.9668 28.5136L94.7705 30.3776C94.7559 30.5169 94.8655 30.6382 95.0061 30.6382H98.7631C98.894 30.6382 99 30.5326 99 30.4023V12.1955C99 12.0651 98.894 11.9595 98.7631 11.9595H95.006C94.8652 11.9595 94.7554 12.0811 94.7704 12.2206L94.959 13.9738C94.9866 14.2296 94.5968 14.365 94.4362 14.1635C93.9888 13.6022 93.48 13.1479 92.9339 12.7866C91.6169 11.9152 90.1033 11.5977 88.7521 11.5977ZM84.2176 21.2627C84.2176 19.3698 84.6802 17.5782 85.578 16.2692C86.4692 14.9699 87.7896 14.1412 89.5512 14.1412C91.0868 14.1412 92.372 14.8255 93.2819 16.0396C94.1968 17.2604 94.7395 19.0294 94.7395 21.1903C94.7395 22.9833 94.3641 24.8156 93.5334 26.1889C92.7106 27.5491 91.4464 28.4565 89.6238 28.4565C87.7129 28.4565 86.3735 27.47 85.4985 26.072C84.6171 24.6639 84.2176 22.849 84.2176 21.2627Z"
        fill="#354535"
      />
      <path
        d="M74.0207 4C73.8899 4 73.7838 4.10564 73.7838 4.23595V30.4018C73.7838 30.5321 73.8899 30.6378 74.0207 30.6378H77.5896C77.7204 30.6378 77.8265 30.5321 77.8265 30.4018V4.23595C77.8265 4.10564 77.7204 4 77.5896 4H74.0207Z"
        fill="#354535"
      />
      <path
        d="M54.3624 11.9595C54.2316 11.9595 54.1255 12.0652 54.1255 12.1955V23.6504C54.1255 25.8427 54.7792 27.687 56.0483 28.9844C57.3185 30.2831 59.1722 31 61.5041 31C63.1641 31 64.5044 30.6371 65.5781 29.8278C65.9795 29.5253 66.3388 29.164 66.6614 28.743C66.8168 28.5402 67.1962 28.664 67.1748 28.9182L67.0512 30.3825C67.0396 30.5201 67.1486 30.6382 67.2873 30.6382H70.8613C70.9922 30.6382 71.0982 30.5326 71.0982 30.4023V12.1955C71.0982 12.0652 70.9922 11.9595 70.8613 11.9595H67.2925C67.1616 11.9595 67.0556 12.0652 67.0556 12.1955V22.8907C67.0556 24.3952 66.6336 25.8019 65.8373 26.8252C65.047 27.841 63.8792 28.4927 62.3395 28.4927C60.9435 28.4927 59.9144 28.0067 59.2291 27.1958C58.5377 26.3778 58.1682 25.1955 58.1682 23.759V12.1955C58.1682 12.0652 58.0621 11.9595 57.9313 11.9595H54.3624Z"
        fill="#354535"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.3028 6.48735C34.172 6.48735 34.0659 6.59299 34.0659 6.72331V30.4011C34.0659 30.5314 34.172 30.637 34.3028 30.637H38.0298C38.1607 30.637 38.2667 30.5314 38.2667 30.4011V19.6789C38.2667 19.595 38.335 19.527 38.4192 19.527C39.3521 19.527 40.2775 19.7862 40.9957 20.3792C43.6433 22.5652 45.931 25.9949 48.2377 30.4767L48.2542 30.5087C48.2947 30.5875 48.3761 30.637 48.465 30.637H52.5679C52.7454 30.637 52.8598 30.4498 52.7784 30.2928L52.7337 30.2067C50.4691 25.8417 48.138 22.2865 45.4526 19.8028C45.3009 19.6625 45.3841 19.4033 45.5888 19.3731C47.2018 19.1356 48.5902 18.5858 49.6392 17.7053C50.9638 16.5936 51.717 14.9797 51.717 12.8928C51.717 10.9901 50.9139 9.37957 49.5428 8.25011C48.176 7.12411 46.2599 6.48735 44.0396 6.48735H34.3028ZM38.2667 8.94702C38.2667 8.8167 38.3728 8.71106 38.5036 8.71106H43.1867C45.8074 8.71106 47.5162 10.6661 47.5162 12.8601C47.5162 14.2542 47.0929 15.3551 46.3143 16.1082C45.5347 16.8622 44.3619 17.3033 42.793 17.3033H38.5036C38.3728 17.3033 38.2667 17.1977 38.2667 17.0673V8.94702Z"
        fill="#354535"
      />
    </svg>
  );
};

export default RulaLogo;
