import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import { AuthenticatedState } from '@/utils/types';
import { Action } from '../actions';

const careTeamPageReducer = produce(
  (state: WritableDraft<AuthenticatedState>, action: Action) => {
    if (state.ui.page.path === 'care-team') {
      if (action.type === 'careTeam/setLicenseTooltipOpen') {
        const { page, open, activeTooltipNpi } = action.payload;
        state.ui.page[page].licenseTooltipOpen = open;
        if (page === 'root') {
          state.ui.page[page].activeTooltipNpi = activeTooltipNpi;
        }
      }
      if (action.type === 'careTeam/setPracticeTooltipOpen') {
        const { page, open } = action.payload;
        state.ui.page[page].practiceTooltipOpen = open;
      }
      if (action.type === 'careTeam/setAvailability') {
        state.ui.page.rematch.availability = action.payload;
      }
      if (action.type === 'careTeam/setAvailabilityPickerOpen') {
        state.ui.page.rematch.availabilityPickerOpen = action.payload;
      }
      if (action.type === 'careTeam/setSpecializationFilter') {
        state.ui.page.rematch.specializationFilter = action.payload;
      }
      if (action.type === 'careTeam/setModalityFilter') {
        state.ui.page.rematch.modalityFilter = action.payload;
      }
      if (action.type === 'careTeam/setIdentityFilters') {
        state.ui.page.rematch.identityFilters = action.payload;
      }
      if (action.type === 'careTeam/setRematchProviders') {
        state.ui.page.rematch.rematchProviders = {
          loadingState: 'done',
          rows: action.payload,
        };
      }
      if (action.type === 'careTeam/setRematchProvidersLoadingState') {
        state.ui.page.rematch.rematchProviders.loadingState = action.payload;
      }
      if (action.type === 'careTeam/setRematchProviderToReplace') {
        state.ui.page.rematch.providerToReplace = action.payload;
      }
    }
  }
);

export default careTeamPageReducer;
