import {
  CareTeamV3,
  TherapyType,
  InsuranceDetails,
  ActiveProviderShapeV3,
  InactiveProviderShape,
} from '@/utils/types';
import { therapyTypes as allTherapyTypes, AGE_BANDS } from '@/utils/constants';
import { getAgeFromBirthdate } from '@/utils/dates';
import { useFlagCheck } from '@/utils/use-feature-flags';
import { getPsychEligibility } from './tools';

export const getProviderFromCareTeamByNpi = (
  npi: number,
  careTeam: (ActiveProviderShapeV3 | InactiveProviderShape)[]
): ActiveProviderShapeV3 | InactiveProviderShape | undefined => {
  return careTeam.find((provider) => provider.npi === npi);
};

export const getCareTypes = ({
  patientState,
  birthdate,
  insurance,
  careTeam,
}: {
  patientState: string;
  birthdate: string;
  insurance: InsuranceDetails;
  careTeam: CareTeamV3;
}) => {
  const { getFlag } = useFlagCheck();
  let statesWithPsych = getFlag('ppbReleaseNewStatesForPsychSsEligibility') || [
    'CA',
  ];
  statesWithPsych = statesWithPsych.concat(['MB']);

  const hidePsych = !getPsychEligibility(
    birthdate,
    patientState,
    statesWithPsych,
    insurance
  );

  const careTeamTypesSet = new Set<TherapyType>(
    careTeam.active
      .map(({ active_therapy_types }) =>
        active_therapy_types.map(({ therapy_type }) => therapy_type)
      )
      .flat()
  );

  let therapyTypes = allTherapyTypes;
  const age = getAgeFromBirthdate(birthdate);
  if (age < AGE_BANDS.ADULT.MIN) {
    therapyTypes = ['individual', 'psychiatric'];
  }
  const availableCareTypes = therapyTypes.filter(
    (therapyType) => !careTeamTypesSet.has(therapyType)
  );
  if (hidePsych && availableCareTypes.includes('psychiatric')) {
    availableCareTypes.splice(
      availableCareTypes.findIndex((type) => type === 'psychiatric'),
      1
    );
  }

  const careTeamTypesArray = Array.from(careTeamTypesSet);

  return { availableCareTypes, careTeamTypesArray, hidePsych };
};
