import { Override } from '../utils/types';
import { twMerge } from 'tailwind-merge';
import Loader from './Loader';
import IconComponent, { IconProps } from './icons/Icon';
import Text from './Text';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'flat'
  | 'primary-outline'
  | 'primary-flat'
  | 'tertiary-no-border'
  | ''
  | undefined;

type ButtonState = 'waiting' | 'disabled' | '' | undefined;

type OverrideProps = {
  state?: ButtonState;
  variant?: ButtonVariant;
  size?: 'small' | 'medium' | 'medium-no-px' | 'large';
  children: string | JSX.Element;
  renderIcon?: (props: IconProps) => ReturnType<typeof IconComponent>;
  sizeClasses?: string;
  align?: 'left' | 'right' | 'center';
  width?: string;
};

export type ButtonProps = Override<
  Omit<JSX.IntrinsicElements['button'], 'className'>,
  OverrideProps
>;

const getColor = (state: ButtonState, variant: ButtonVariant) => {
  if (state === 'disabled') {
    return getDisabledColor(variant);
  }
  return getActiveColor(variant);
};

const getActiveColor = (variant: ButtonVariant) => {
  switch (variant) {
    case 'primary':
      return 'border-1 border-primary-3 bg-primary-3 group-hover:bg-primary-4 text-tertiary-0';
    case 'primary-outline':
      return 'border-1 border-tertiary-2 hover:border-primary-4 hover:text-primary-4';
    case 'secondary':
      return 'bg-secondary-3 group-hover:bg-secondary-4 text-tertiary-0';
    case 'primary-flat':
      return 'text-primary-3 group-hover:text-primary-4';
    case 'flat':
    case 'tertiary-no-border':
      return 'group-hover:text-primary-3';
    default:
      return 'border-1 border-tertiary-2 group-hover:border-tertiary-3';
  }
};

const getDisabledColor = (variant: ButtonVariant) => {
  switch (variant) {
    case 'primary':
      return 'border-1 border-primary-3 bg-primary-3 text-tertiary-0 opacity-50';
    case 'primary-outline':
      return 'border-1 border-tertiary-2 opacity-50';
    case 'secondary':
      return 'bg-secondary-3 text-tertiary-0';
    case 'primary-flat':
      return 'text-primary-3';
    case 'flat':
    case 'tertiary-no-border':
      return '';
    default:
      return 'border-1 border-tertiary-2';
  }
};

const Button = ({
  state,
  children,
  variant,
  size = 'large',
  renderIcon,
  sizeClasses = '',
  type = 'button',
  align = 'center',
  width = 'w-full',
  ...props
}: ButtonProps) => {
  const color = getColor(state, variant);

  const padding =
    size === 'small'
      ? 'p-2'
      : size === 'medium'
      ? 'py-2 px-3'
      : size === 'medium-no-px'
      ? 'py-2'
      : 'py-3 px-4';
  // needs to match the background for the button
  const loaderBorder =
    variant === 'primary'
      ? 'border-tertiary-0 border-b-primary-3 group-hover:border-b-primary-4'
      : variant === 'secondary'
      ? 'border-tertiary-0 border-b-secondary-3 group-hover:border-b-secondary-4'
      : '';

  const alignment =
    align === 'left'
      ? 'justify-start'
      : align === 'right'
      ? 'justify-end'
      : 'justify-center';

  const classes = twMerge(
    `${width} rounded ${color} ${padding} ${alignment} flex break-normal`
  );

  return (
    <div className={`group ${sizeClasses}`}>
      <button
        role="button"
        className={classes}
        disabled={state === 'waiting' || state === 'disabled'}
        type={type}
        {...props}
      >
        <span className="relative">
          <span className={state === 'waiting' ? 'invisible' : ''}>
            <span
              className={
                renderIcon
                  ? `flex items-center ${
                      size === 'small' ? 'gap-x-2' : 'gap-x-[10px]'
                    }`
                  : ''
              }
            >
              {renderIcon &&
                renderIcon({
                  size: 16,
                  stroke:
                    variant === 'primary' || variant === 'secondary'
                      ? 'tertiary-0'
                      : undefined,
                })}
              {size === 'small' ? (
                <Text.Small.Bold>{children}</Text.Small.Bold>
              ) : (
                <Text.P.Bold>{children}</Text.P.Bold>
              )}
            </span>
          </span>
          <Loader
            className={`${
              state === 'waiting' ? '' : 'invisible'
            } absolute border-[3px] h-6 w-6 top-0 left-[calc(50%-12px)] ${loaderBorder}`}
          ></Loader>
        </span>
      </button>
    </div>
  );
};

export default Button;
