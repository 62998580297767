import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MobileContext, StateContext } from '@/App';
import Button from '@/components/Button';
import CheckCircle from '@/components/icons/CheckCircle';
import {
  AnalyticsEvent,
  JSONValue,
  TherapyType,
  ProductArea,
} from '@/utils/types';
import trackEvent from '@/utils/amplitude';
import Dollar from '@/components/icons/Dollar';
import TwoPeople from '@/components/icons/TwoPeople';
import User from '@/components/icons/User';
import Text from '@/components/Text';
import actions from '@/state/actions';
import useData from '@/state/use-data';
import {
  therapyTypeHeader,
  MODALITY_TOOLTIPS,
  supportEmail,
} from '@/utils/constants';
import { makeCorrectCasing } from '@/utils/format';
import stopPropagation from '@/utils/stop-propagation';
import WithPageState from '@/pages/WithPageState';
import AvailabilityPickerButton from './AvailabilityPickerButton';
import Gender from '@/components/icons/Gender';
import Identity from '@/components/icons/Identity';
import IdentityModalTrigger from '@/pages/care-team/replace-flow/IdentityModalTrigger';
import ProviderResults from '@/pages/care-team/replace-flow/ProviderResults';
import FullWidthHeader from '@/components/FullWidthHeader';
import Language from '@/components/icons/Language';
import InfoCircle from '@/components/icons/InfoCircle';
import InfoCard from '@/components/InfoCard';
import PreferenceModalTrigger from '@/pages/care-team/replace-flow/PreferenceModalTrigger';
import PreferenceModal from '@/pages/care-team/replace-flow/PreferenceModal';
import WithProviderFromCareTeamV2 from '../WithProviderFromCareTeamV2';

const FindProviderV2 = () => {
  const { providerNpi, therapyType } = useParams();
  const { data, WithData } = useData([
    'insurance',
    'providerOptions',
    'providerModalities',
    'providerSpecializations',
    'advancedMdOfficeKeyStateMap',
    'patientData',
    'profile',
  ]);
  const { dispatch } = useContext(StateContext);
  const mobile = useContext(MobileContext);

  const toggleToolTip = (open: boolean) => {
    dispatch(
      actions.careTeam.setPracticeTooltipOpen({
        open,
        page: 'root',
      })
    );
  };

  // How do we get the therapy_type we are replacing? Options...
  // 1. Grab the therapy_type from the header
  // 2. store it on reducer

  // do we need to consider deeplinking to replace page? may need to grab the therapy_type from the URL
  return (
    <WithProviderFromCareTeamV2 providerNpi={providerNpi}>
      {({ provider }) => (
        <WithData data={data}>
          {({
            insurance,
            providerModalities,
            providerSpecializations,
            advancedMdOfficeKeyStateMap,
            patientData,
            providerOptions,
            profile,
          }) => {
            return (
              <WithPageState page="care-team">
                {({ pageState }) => {
                  const { rematch } = pageState;
                  const { patient_record_uuid } = patientData;

                  const {
                    rematchProviders,
                    identityFilters,
                    specializationFilter,
                    modalityFilter,
                    providerToReplace,
                    selectedProvider,
                    availability,
                  } = rematch;

                  const onProvidersLoaded = () => {
                    if (rematchProviders.loadingState !== 'done') return;

                    const metadata: { [x: string]: JSONValue } = {
                      provider_search_all_results_count:
                        rematchProviders.rows.length,
                    };
                    let product_area: ProductArea =
                      'AddCareTeamProviderResults';
                    if (provider) {
                      metadata.provider_npi = provider.npi.toString();
                      metadata.original_provider_npi = provider.npi;
                      product_area = 'RematchProviderResults';
                    }
                    const providerResultsEvent: AnalyticsEvent = {
                      product_area,
                      name: 'page_view',
                      trigger: 'Results loaded',
                      metadata,
                    };

                    trackEvent(providerResultsEvent);
                  };

                  useEffect(onProvidersLoaded, [rematchProviders.loadingState]);

                  return (
                    <div className="space-y-10">
                      <FullWidthHeader>
                        Find the right provider for you
                      </FullWidthHeader>
                      <div className="flex-col gap-6 w-full flex lg:flex-row">
                        <div className="flex-none md:flex-1:w-full">
                          <div className="p-4 border-1 rounded border-tertiary-2">
                            <Text.H4 className="mb-4">Your preferences</Text.H4>
                            <Text.P.Bold className="mb-3">
                              Availability
                            </Text.P.Bold>
                            <div className="mb-4 w-full">
                              <AvailabilityPickerButton />
                            </div>
                            {
                              <PreferenceModalTrigger
                                buttonText="Add a specialty"
                                heading="Specialty"
                                rematchFilter={specializationFilter}
                                modal={
                                  <PreferenceModal
                                    data={providerSpecializations.data}
                                    heading="Specialties"
                                    selected={specializationFilter}
                                    onCheck={(s) =>
                                      dispatch(
                                        actions.careTeam.setSpecializationFilter(
                                          s
                                        )
                                      )
                                    }
                                  />
                                }
                              />
                            }
                            {
                              <PreferenceModalTrigger
                                buttonText="Add a method"
                                heading="Method"
                                rematchFilter={modalityFilter}
                                modal={
                                  <PreferenceModal
                                    data={providerModalities.data}
                                    heading="Method"
                                    selected={modalityFilter}
                                    categoryTooltips={MODALITY_TOOLTIPS}
                                    onCheck={(modality) =>
                                      dispatch(
                                        actions.careTeam.setModalityFilter(
                                          modality
                                        )
                                      )
                                    }
                                  />
                                }
                              />
                            }
                            <div className="border-b-1 border-tertiary-2 w-full mb-4"></div>
                            <div className="relative space-y-4 mb-3">
                              <div className="flex justify-between items-center">
                                <Text.P.Bold>Practice</Text.P.Bold>
                                <div>
                                  <InfoCircle.Small
                                    className="inline-block"
                                    onClick={stopPropagation((e) => {
                                      e.preventDefault();
                                      toggleToolTip(
                                        !pageState.root.practiceTooltipOpen
                                      );
                                    })}
                                    onMouseEnter={
                                      mobile
                                        ? undefined
                                        : () => toggleToolTip(true)
                                    }
                                    onMouseLeave={
                                      mobile
                                        ? undefined
                                        : () => toggleToolTip(false)
                                    }
                                  />
                                  {pageState.root.practiceTooltipOpen && (
                                    <div className="flex-grow z-10 absolute top-0 left-0 translate-y-[calc(-100%)] md:translate-y-0 md:left-[256px] md:max-w-[256px] md:w-max max-w-prose items-center">
                                      <InfoCard>
                                        <Text.P>
                                          You can update your medical billing
                                          method on your Rula profile. To search
                                          for a therapist in a different state,
                                          contact {supportEmail} to update your
                                          location.
                                        </Text.P>
                                      </InfoCard>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="flex gap-2">
                                <Dollar />
                                {insurance.payment_method ===
                                'Health Insurance' ? (
                                  <Text.P>
                                    Accepts{' '}
                                    {insurance.display_name ||
                                      insurance.carrier}
                                  </Text.P>
                                ) : (
                                  <Text.P>Cash pay</Text.P>
                                )}
                              </div>
                              <div className="flex gap-2">
                                {therapyType === 'individual' ? (
                                  <User />
                                ) : (
                                  <TwoPeople />
                                )}
                                <Text.P>
                                  {
                                    therapyTypeHeader[
                                      therapyType as TherapyType
                                    ]
                                  }
                                </Text.P>
                              </div>
                              <div className="flex gap-2">
                                <CheckCircle />
                                <Text.P>
                                  Licensed in{' '}
                                  {
                                    advancedMdOfficeKeyStateMap.data[
                                      patientData.advanced_md_office_key
                                    ]
                                  }
                                </Text.P>
                              </div>
                            </div>
                            <div className="border-b-1 border-tertiary-2 w-full mb-2"></div>
                            <div className="space-y-4">
                              <div className="flex justify-between items-center">
                                <Text.P.Bold>Identity</Text.P.Bold>
                                <IdentityModalTrigger
                                  providerOptions={providerOptions.data}
                                >
                                  {({ onClick }) => (
                                    <Button
                                      onClick={onClick}
                                      variant="primary-flat"
                                      size="small"
                                    >
                                      Edit
                                    </Button>
                                  )}
                                </IdentityModalTrigger>
                              </div>
                              <div className="flex gap-2">
                                <Gender />
                                <Text.P>
                                  {identityFilters?.gender
                                    ? makeCorrectCasing(
                                        identityFilters.gender,
                                        true
                                      )
                                    : 'Any gender'}
                                </Text.P>
                              </div>
                              <div className="flex gap-2">
                                <Identity />
                                <Text.P>
                                  {identityFilters?.ethnicity
                                    ? makeCorrectCasing(
                                        identityFilters.ethnicity,
                                        true
                                      )
                                    : 'Any ethnicity'}
                                </Text.P>
                              </div>
                              <div className="flex gap-2">
                                <Language />
                                <Text.P>
                                  {identityFilters?.language
                                    ? makeCorrectCasing(
                                        identityFilters.language,
                                        true
                                      )
                                    : 'Any language'}
                                </Text.P>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="md:w-full md:flex-1 relative">
                          <ProviderResults
                            providerToReplace={providerToReplace}
                            providers={
                              rematchProviders || {
                                loadingState: 'pending',
                              }
                            }
                            selectedProvider={selectedProvider}
                            specializationFilter={specializationFilter}
                            modalityFilter={modalityFilter}
                            patient_record_uuid={patient_record_uuid}
                            availability={availability}
                            networkName={insurance.carrier}
                            therapyType={therapyType as TherapyType}
                            gender={identityFilters?.gender}
                            ethnicity={identityFilters?.ethnicity}
                            language={identityFilters?.language}
                            birthdate={profile.birthdate}
                          />
                        </div>
                      </div>
                    </div>
                  );
                }}
              </WithPageState>
            );
          }}
        </WithData>
      )}
    </WithProviderFromCareTeamV2>
  );
};

export default FindProviderV2;
