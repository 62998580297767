// This is copied from tailwind.config.js because the tailwind config uses commonjs which is incompatible with the
// rest of the project. Hopefully soon we can use the tailwindcss/resolveConfig utility to expose tailwind config
// to the rest of the project. Expect these to change but only during a full rebranding.
export default {
  colors: {
    'primary-0': '#F1EFF5',
    'primary-1': '#DDD9EE',
    'primary-2': '#C8C0E8',
    'primary-3': '#5937E2',
    'primary-4': '#371E9B',
    'primary-5': '#E6E3F2',
    'secondary-0': '#EBF3F0',
    'secondary-1': '#D0E8DD',
    'secondary-2': '#A5D1C2',
    'secondary-3': '#009F79',
    'secondary-4': '#00634B',
    'secondary-5': '#073429',
    'tertiary-0': '#FFFFFF',
    'tertiary-1': '#F4F4F4',
    'tertiary-2': '#DEDEDE',
    'tertiary-3': '#ACACAC',
    'tertiary-4': '#777777',
    'tertiary-5': '#4B4B4B',
    'tertiary-6': '#303030',
    'tertiary-7': '#262626',
    'quaternary-0': '#FBFAFA',
    'warning-0': '#FAE8E8',
    'warning-1': '#CE1D1D',
  },
};
