import Icon, { IconProps } from './Icon';

const Plus = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        d="M6.99996 1.16602V12.8327M1.16663 6.99935H12.8333"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default Plus;
