import {
  CarrierOption,
  CreditCardDetails,
  EditableCreditCardDetails,
  EditableInsuranceDetails,
  EditingPageData,
  InsuranceDetails,
  ProfileDetails,
} from '../../utils/types';

// split out getting advanced_md_office_key
// and patient_record_uuid
export default {
  setCarriers: (payload: CarrierOption[]) =>
    ({ type: 'profile/setCarriers', payload } as const),
  setProfile: (payload: {
    details: ProfileDetails;
    advanced_md_office_key: number;
    salesforce_contact_id: string;
    patient_record_uuid: string;
    patient_uuid: string;
  }) => ({ type: 'profile/setProfile', payload } as const),
  setPaymentInfo: (payload: {
    creditCard: CreditCardDetails;
    insurance: InsuranceDetails;
  }) => ({ type: 'profile/setPaymentInfo', payload } as const),
  setEditedProfileDetail: (payload: Partial<ProfileDetails>) =>
    ({ type: 'profile/setEditedProfileDetail', payload } as const),
  setEditedProfileDetailAddress: (
    payload: Partial<ProfileDetails['address']>
  ) => ({ type: 'profile/setEditedProfileDetailAddress', payload } as const),
  setEditedBillingAddress: (
    payload: Partial<EditableCreditCardDetails['billing_address']>
  ) => ({ type: 'profile/setEditedBillingAddress', payload } as const),
  setDetailsEditMode: (payload: 'read' | 'edit', ecEdit = false) =>
    ({ type: 'profile/details/setEditMode', payload, ecEdit } as const),
  setCcEditMode: (payload: 'read' | 'edit') =>
    ({ type: 'profile/creditCard/setEditMode', payload } as const),
  setInsuranceEditMode: (payload: 'read' | 'edit') =>
    ({ type: 'profile/insurance/setEditMode', payload } as const),
  setDetailsPersistence: (payload: 'saving' | 'saved' | 'error') =>
    ({ type: 'profile/setDetailsPersistence', payload } as const),
  setDetailsPersisted: () => ({ type: 'profile/setDetailsPersisted' } as const),
  setCcDetailsPersistence: (payload: 'saving' | 'saved' | 'error') =>
    ({ type: 'profile/creditCard/setDetailsPersistence', payload } as const),
  setCcDetailsPersisted: () =>
    ({ type: 'profile/creditCard/setDetailsPersisted' } as const),
  setInvalid: (payload: { invalid: boolean; key: keyof EditingPageData }) =>
    ({ type: 'profile/setInvalid', payload } as const),
  setDetailsInvalid: (payload: boolean) =>
    ({ type: 'profile/setDetailsInvalid', payload } as const),
  setEditedPaymentMethod: (payload: 'Health Insurance' | 'Self Pay') =>
    ({ type: 'profile/setEditedPaymentMethod', payload } as const),
  setEditedCcDetails: (payload: Partial<EditableCreditCardDetails>) =>
    ({ type: 'profile/setEditedCcDetails', payload } as const),
  setEditedInsurance: (payload: Partial<EditableInsuranceDetails>) =>
    ({ type: 'profile/setEditedInsurance', payload } as const),
  setInsurancePersisted: (payload: { network_name: string }) =>
    ({ type: 'profile/insurance/setPersisted', payload } as const),
  setInsurancePersistence: (payload: 'saving' | 'saved' | 'error') =>
    ({ type: 'profile/insurance/setPersistence', payload } as const),
  setInsuranceFrontOfCard: (payload: File | undefined) =>
    ({
      type: 'profile/insurance/setFrontOfCard',
      payload,
    } as const),
  setInsuranceBackOfCard: (payload: File | undefined) =>
    ({
      type: 'profile/insurance/setBackOfCard',
      payload,
    } as const),
  setNameTooltipOpen: (payload: boolean) =>
    ({
      type: 'profile/setNameTooltipOpen',
      payload,
    } as const),
  setPreferredNameTooltipOpen: (payload: boolean) =>
    ({
      type: 'profile/setPreferredNameTooltipOpen',
      payload,
    } as const),
  setEmailTooltipOpen: (payload: boolean) =>
    ({
      type: 'profile/setEmailTooltipOpen',
      payload,
    } as const),
  setSexAtBirthTooltipOpen: (payload: boolean) =>
    ({
      type: 'profile/setSexAtBirthTooltipOpen',
      payload,
    } as const),
  setSexOnInsuranceCardTooltipOpen: (payload: boolean) =>
    ({
      type: 'profile/setSexOnInsuranceCardTooltipOpen',
      payload,
    } as const),
};
