import { useContext } from 'react';
import { StateContext } from '../App';
import Logo from '@/components/assets/Logo';
import Text from '@/components/Text';
import { useLogout } from '../utils/session';
import Button from '@/components/Button';

const MinorManagedCarePage = () => {
  const { dispatch } = useContext(StateContext);
  const logout = useLogout(dispatch);

  return (
    <div className="overflow-hidden h-screen relative">
      <div className="flex justify-between items-center mt-5 mx-5 md:mt-8 md:ml-8">
        <Logo />
        <div className="w-[100px] inline-flex" onClick={logout}>
          <Button variant="primary-outline">Log out</Button>
        </div>
      </div>
      <div className="flex w-full h-full flex-col items-center">
        <div className="flex md:min-w-[33%] flex-col px-12 justify-center align-center text-center relative mt-16 md:mt-[160px]">
          <Text.Serif.H1>Your care is managed by a guardian</Text.Serif.H1>
          <Text.P className="flex justify-center align-center pt-9 pb-3">
            Please reach out to your guardian to make changes.
          </Text.P>
          <Text.P>
            <Text.P.Inline.Bold>Are you the guardian?</Text.P.Inline.Bold>{' '}
            <a
              href="https://patientsupport.rula.com/hc/en-us/articles/22397140558235-Managing-a-minor-s-care-through-the-Rula-patient-portal"
              className="text-primary-3 no-underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here
            </a>{' '}
            for detailed instructions on how to manage care for someone else.
          </Text.P>
        </div>
      </div>
    </div>
  );
};

export default MinorManagedCarePage;
