const NoProviders = ({ fullScreen = false }) => (
  <div
    className={`bg-primary-0 flex items-center justify-center ${
      fullScreen
        ? 'h-[calc(100vh-48px)] -m-5 md:-mx-12 md:-my-9 md:h-[calc(100vh-80px)]'
        : 'h-full w-full'
    }`}
  >
    <div className="text-center">
      <h2 className="text-tertiary-4 font-medium text-lg">
        No current providers
      </h2>
      <div className="text-tertiary-4 text-sm">
        Contact support to expand your care team
      </div>
    </div>
  </div>
);

export default NoProviders;
