import React, { Ref, useEffect, useMemo } from 'react';
import { Override } from '@/utils/types';
import { CardNumberVerification } from 'card-validator/dist/card-number';
import validate from 'card-validator';
import { changeEventWrapper } from '@/utils/on-change';

type OverrideProps = {
  value?: string;
  onEnter?: () => unknown;
  format?: (v: string) => string;
  setRef?: (ref: Ref<HTMLInputElement>) => unknown;
  state?: 'disabled' | 'error' | '';
  onChange?: (value: string) => unknown;
  label?: string | undefined;
  id?: string | undefined;
};

export type TextInputProps = Override<
  JSX.IntrinsicElements['input'],
  OverrideProps
>;

const TextInput = React.forwardRef(
  (
    {
      state = '',
      value = '',
      format = (v) => v,
      onEnter,
      onChange: originalOnChange,
      id,
      ...props
    }: TextInputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const borderColor =
      state === 'error' ? 'border-warning-1' : 'border-tertiary-3';
    const onChange = originalOnChange
      ? (v: string) => {
          const updated = format(v);
          if (updated !== value) {
            originalOnChange(updated);
          }
        }
      : undefined;
    const onKeyDown = onChange
      ? (e: React.KeyboardEvent<HTMLInputElement>) => {
          const start = e.currentTarget.selectionStart;
          const end = e.currentTarget.selectionEnd;
          if (start && end) {
            // if user presses Backspace or Delete and the character(s) they are trying to delete
            // won't get deleted because they're part of formatting, just move the cursor,
            // and don't try to change anything. Otherwise, the cursor gets moved to the end
            // of the input.
            if (e.key === 'Backspace') {
              const updated =
                value.slice(0, start === end ? start - 1 : start) +
                value.slice(end);
              if (format(updated) === value) {
                e.currentTarget.setSelectionRange(start - 1, start - 1);
                e.preventDefault();
              }
            }
            if (e.key === 'Delete') {
              const updated =
                value.slice(0, start) +
                value.slice(start === end ? end + 1 : end);
              if (format(updated) === value) {
                e.currentTarget.setSelectionRange(end + 1, end + 1);
                e.preventDefault();
              }
            }
          }
        }
      : undefined;
    return (
      <input
        className={`${borderColor} border-1 rounded pt-3 pr-2 pb-3 pl-2 text-tertiary-6 w-full`}
        type="text"
        onKeyUp={onEnter ? (e) => e.key === 'Enter' && onEnter() : undefined}
        onKeyDown={onKeyDown}
        {...props}
        disabled={state === 'disabled'}
        value={value}
        onChange={changeEventWrapper(onChange)}
        ref={ref}
        id={id}
      />
    );
  }
);

export type CreditCardInputProps = Override<
  Omit<TextInputProps, 'ref'>,
  {
    setValidation?: (validation: CardNumberVerification) => unknown;
  }
>;

export const CreditCardInput = ({
  value = '',
  setValidation,
  ...props
}: CreditCardInputProps) => {
  const validation = useMemo<CardNumberVerification>(
    () => validate.number(value.replace(/ /g, '')),
    [value]
  );

  const cardType = validation.card ? validation.card.type : 'visa';

  useEffect(() => {
    if (setValidation) {
      setValidation(validation);
    }
  }, [validation]);

  const format = (v: string) => {
    if (cardType === 'american-express') {
      const regex = /^(\d{1,4})?(\s)?(\d{1,6})?(\s)?(\d{1,5})?(.*)$/g;
      return v.replace(
        regex,
        (_match, first4 = '', space1, next6 = '', space2, last5 = '') => {
          return `${first4}${
            space1 && first4.length === 4 ? ' ' : next6.length ? ' ' : ''
          }${next6}${
            space2 && next6.length === 6 ? ' ' : last5.length ? ' ' : ''
          }${last5}`;
        }
      );
    } else {
      const regex =
        /^(\d{1,4})?(\s)?(\d{1,4})?(\s)?(\d{1,4})?(\s)?(\d{1,4})?(.*)$/g;
      return v.replace(
        regex,
        (
          _match,
          first4 = '',
          space1,
          second4 = '',
          space2,
          third4 = '',
          space3,
          last4 = ''
        ) => {
          return `${first4}${
            space1 && first4.length === 4 ? ' ' : second4.length ? ' ' : ''
          }${second4}${
            space2 && second4.length === 4 ? ' ' : third4.length ? ' ' : ''
          }${third4}${
            space3 && third4.length === 4 ? ' ' : last4.length ? ' ' : ''
          }${last4}`;
        }
      );
    }
  };

  return <TextInput format={format} {...props} value={value} />;
};

export default TextInput;
