import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import config from '@/utils/config';
import * as amplitude from '@amplitude/analytics-browser';

import { datadogRum } from '@datadog/browser-rum';

amplitude.init(config.VITE_AMPLITUDE_API_KEY, undefined, {
  // change to LogLevel.Debug if you want to...debug something
  logLevel: amplitude.Types.LogLevel.Error,
});

if (['preview', 'production'].includes(config.VITE_DD_ENV)) {
  datadogRum.init({
    applicationId: 'e425ede8-1f9b-490e-8468-a2f91711dcdc',
    clientToken: 'pube80d5b614861f6dba58801e5e2089deb',
    site: 'us5.datadoghq.com',
    service: config.VITE_DD_SERVICE,
    env: config.VITE_DD_ENV,
    version: config.VITE_DD_VERSION,
    sessionSampleRate: 10,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask',
    compressIntakeRequests: true,
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
  });
}

Sentry.init({
  dsn: config.VITE_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: import.meta.env.MODE,
  tracesSampleRate: 0,
});

//Extensions for DayJS
// TODO Sylvie - see if we need a polyfill for any of this date stuff
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
