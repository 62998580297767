import Icon, { IconProps } from './Icon';

const Gender = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.79332 0C4.1431 0 4.42665 0.275517 4.42665 0.615385C4.42665 0.955252 4.1431 1.23077 3.79332 1.23077H3.11019L3.89066 1.98913L4.48563 1.41101C4.73296 1.17069 5.13397 1.17069 5.3813 1.41101C5.62863 1.65133 5.62863 2.04097 5.3813 2.2813L4.78632 2.85941L6.06968 4.1064C7.16853 3.21478 8.58312 2.67823 10.1267 2.67823C11.6055 2.67823 12.966 3.17073 14.0438 3.99613L16.8898 1.23077H16.2067C15.8569 1.23077 15.5734 0.955252 15.5734 0.615385C15.5734 0.275517 15.8569 0 16.2067 0H19.5V3.11157C19.5 3.45143 19.2164 3.72695 18.8667 3.72695C18.5169 3.72695 18.2333 3.45143 18.2333 3.11157V2.53617L15.3445 5.34317C16.048 6.33469 16.46 7.53664 16.46 8.83208C16.46 11.9395 14.0896 14.509 11.0098 14.9266C11.0122 14.9533 11.0135 14.9803 11.0135 15.0077V16.6154H11.9001C12.2498 16.6154 12.5334 16.8909 12.5334 17.2308C12.5334 17.5706 12.2498 17.8462 11.9001 17.8462H11.0135V19.0769C11.0135 19.5867 10.5881 20 10.0635 20C9.5388 20 9.11347 19.5867 9.11347 19.0769V17.8462H8.10009C7.75031 17.8462 7.46675 17.5706 7.46675 17.2308C7.46675 16.8909 7.75031 16.6154 8.10009 16.6154H9.11347V15.0077C9.11347 14.9741 9.11532 14.941 9.1189 14.9084C6.10008 14.4394 3.79338 11.8975 3.79338 8.83208C3.79338 7.60024 4.16587 6.45295 4.80738 5.49073L3.44283 4.16484L2.84798 4.74284C2.60065 4.98316 2.19964 4.98316 1.95231 4.74284C1.70498 4.50251 1.70498 4.11287 1.95231 3.87255L2.54716 3.29455L1.76666 2.53617V3.11157C1.76666 3.45143 1.48311 3.72695 1.13333 3.72695C0.783552 3.72695 0.5 3.45143 0.5 3.11157V0H3.79332ZM14.56 8.83208C14.56 11.2111 12.5751 13.1398 10.1267 13.1398C7.67823 13.1398 5.69337 11.2111 5.69337 8.83208C5.69337 6.453 7.67823 4.52438 10.1267 4.52438C12.5751 4.52438 14.56 6.453 14.56 8.83208Z"
        fill="#262626"
        strokeWidth="0.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default Gender;
