import { TherapyType } from '@/utils/types';
import ReplaceProviderTriggerV2 from './ReplaceProviderTriggerV2';
import Text from '@/components/Text';
import stopPropagation from '@/utils/stop-propagation';

const ReplaceProviderButtonV2 = ({
  providerNpi,
  therapyType,
}: {
  providerNpi: number;
  therapyType: TherapyType;
}) => {
  return (
    <ReplaceProviderTriggerV2
      providerNpi={providerNpi}
      therapyType={therapyType}
    >
      {({ onClick }) => (
        <Text.P
          role="button"
          onClick={stopPropagation((e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick();
          })}
        >
          Replace provider
        </Text.P>
      )}
    </ReplaceProviderTriggerV2>
  );
};

export default ReplaceProviderButtonV2;
