import { useContext, useEffect, useState, useMemo } from 'react';
import { StateContext } from './App';
import { useSession, useTimeout } from './utils/session';
import Modal from './components/Modal';
import actions from './state/actions';
import SessionExpiryModal from './components/Modals/SessionExpiryModal';

const SessionExpiryWarning = () => {
  const { state, dispatch } = useContext(StateContext);
  const logout = useTimeout(dispatch);
  const { timeLeft } = useSession();
  const [check, setCheck] = useState(true);
  const [hidden, setHidden] = useState(false);
  const modal = useMemo(() => state.ui.modal, []);

  useEffect(() => {
    setTimeout(() => {
      setCheck(!check);
    }, 990);

    if (timeLeft <= 0) {
      void logout();
    }
  }, [timeLeft, check]);

  const closeModal = () => {
    setHidden(true);
    dispatch(actions.setModal(null));
  };

  return timeLeft <= 300 && !hidden ? (
    <div
      className="w-full h-full flex justify-center items-center fixed top-0 z-20 bg-tertiary-7 bg-opacity-50 z-10"
      onClick={closeModal}
    >
      <Modal {...modal}>
        <SessionExpiryModal onClose={closeModal} />
      </Modal>
    </div>
  ) : null;
};

export default SessionExpiryWarning;
