type Validation = (v: string) => boolean;

export const isInteger: Validation = (v) => {
  return Number.isInteger(Number(v));
};

export const max =
  (max: number): Validation =>
  (v) =>
    !Number.isNaN(Number(v)) && Number(v) <= max;

export const min =
  (min: number): Validation =>
  (v) =>
    !Number.isNaN(Number(v)) && Number(v) >= min;

export const all = (validations: Validation[]) => (v: string) =>
  validations.reduce((valid, validate) => valid && validate(v), true);

export const any = (validations: Validation[]) => (v: string) =>
  validations.reduce((valid, validate) => valid || validate(v), false);

export const match = (regex: RegExp) => (v: string) => {
  return !!v.match(regex);
};
