import dayjs from 'dayjs';
import {
  Availability,
  IdentityFilters,
  ProviderDetail,
  ProviderBase,
  Role,
  ServerData,
  TherapyType,
  WillingToSee,
} from '@/utils/types';
import { getAgeFromBirthdate } from '@/utils/dates';
import { useCallback, useEffect, useMemo, useContext } from 'react';
import SupportLink from '@/components/SupportLink';
import { useNavigate, useParams } from 'react-router-dom';
import deriveAvailability from '@/utils/derive-availability';
import fetch from '@/utils/fetch';
import Loader from '@/components/Loader';
import ErrorPage from '@/pages/ErrorPage';
import SearchResultProviderCard from '@/pages/care-team/replace-flow/SearchResultProviderCard/SearchResultProviderCard';
import AbstractMushroomArt from '@/components/assets/AbstractMushroomArt';
import Text from '@/components/Text';
import { compact } from '@/utils/object';
import actions from '@/state/actions';
import { StateContext } from '@/App';
import useEffectOnUpdate from '@/utils/use-effect-on-update';
import {
  therapyTypeToRole,
  getWillingToSeeFromTherapyType,
} from '@/utils/constants';
import { getProviderDetails } from '@/api/provider.api';

interface ProviderSearchBody {
  patient_record_uuid: string;
  specialization?: string;
  modality?: string;
  therapy_type: TherapyType;
  gender?: string;
  race?: string;
  language?: string;
  network_name?: string;
  availability: Record<string, { start: number; end: number }[]>;
  provider_role: Role;
  time_zone?: string;
  willing_to_see: WillingToSee;
}

interface BaseProps {
  providerToReplace?: number | undefined;
  availability?: Availability;
  specializationFilter?: string;
  modalityFilter?: string;
  patient_record_uuid: string;
  therapyType: TherapyType;
  networkName?: string;
  selectedProvider: ProviderDetail | undefined;
  providers: ServerData<{ rows: ProviderBase[] }>;
  birthdate: string;
}

type ProviderResultsProps = BaseProps & IdentityFilters;

const ProviderResults = ({
  providerToReplace,
  providers,
  specializationFilter,
  modalityFilter,
  availability,
  patient_record_uuid,
  therapyType,
  networkName,
  gender,
  ethnicity,
  language,
  birthdate,
}: ProviderResultsProps) => {
  const { dispatch } = useContext(StateContext);
  const navigate = useNavigate();
  // this param is used mainly for routing purposes.
  // this is 2 sources of truth-ish, def clean this up after we remove FindProviderV1
  const { providerNpi, therapyType: therapyTypeParam } = useParams();
  const body = useMemo(() => {
    const age = getAgeFromBirthdate(birthdate);
    const data: ProviderSearchBody = {
      specialization: specializationFilter,
      modality: modalityFilter,
      availability: deriveAvailability(availability),
      patient_record_uuid,
      therapy_type: therapyType,
      network_name: networkName?.toLowerCase(),
      // Coerce empty strings to undefined, which are removed on compact, otherwise the API errors
      gender: gender || undefined,
      race: ethnicity || undefined,
      language: language || undefined,
      provider_role: therapyTypeToRole[therapyType],
      time_zone: dayjs.tz.guess(),
      willing_to_see: getWillingToSeeFromTherapyType(therapyType, age),
    };
    return data;
  }, [
    providerNpi,
    specializationFilter,
    modalityFilter,
    availability,
    patient_record_uuid,
    therapyType,
    networkName,
    gender,
    ethnicity,
    language,
  ]);

  useEffect(() => {
    if (
      providerToReplace !== Number(providerNpi) ||
      providers.loadingState === 'pending'
    ) {
      dispatch(actions.careTeam.setRematchProvidersLoadingState('needed'));
    }
    return () => {
      dispatch(actions.careTeam.setRematchProvidersLoadingState('pending'));
    };
  }, []);

  useEffectOnUpdate(() => {
    dispatch(actions.careTeam.setRematchProvidersLoadingState('needed'));
  }, [body]);

  useEffect(() => {
    if (providers.loadingState !== 'needed') {
      return;
    }

    dispatch(actions.careTeam.setRematchProvidersLoadingState('in_progress'));
    fetch
      .json('/api/provider_search', {
        method: 'POST',
        body: compact({ ...body }),
      })
      .then((r) => {
        const rows = r.payload.rows.filter(
          (item: ProviderDetail) => Number(item.npi) !== Number(providerNpi)
        );
        dispatch(
          actions.careTeam.setRematchProviderToReplace(Number(providerNpi))
        );
        dispatch(actions.careTeam.setRematchProviders(rows));
      })
      .catch((e) => {
        console.error(e);
        dispatch(actions.careTeam.setRematchProvidersLoadingState('error'));
      });
  }, [providers.loadingState]);

  const onClick = useCallback(
    async (provider: ProviderBase & { bookable_slots?: number[] }) => {
      if (!provider.bookable_slots) {
        provider = await getProviderDetails(
          provider.npi,
          patient_record_uuid,
          true
        );
      }
      const path = providerNpi
        ? `/care-team/${providerNpi}/${therapyTypeParam}/replace/${provider.npi}/choose-appointment`
        : `/care-team/${therapyTypeParam}/add-new-care-type/${provider.npi}/choose-appointment`;
      navigate(path, {
        state: {
          provider,
        },
      });
    },
    [providerNpi]
  );

  if (
    providers.loadingState === 'in_progress' ||
    providers.loadingState === 'needed' ||
    providers.loadingState === 'pending'
  ) {
    return <Loader.Contained />;
  }

  if (providers.loadingState === 'error') {
    return <ErrorPage />;
  }

  if (providers.loadingState === 'done' && providers.rows.length === 0) {
    return (
      <div className="p-4 flex justify-center w-full h-full items-center">
        <div className="flex flex-col justify-center items-center">
          <AbstractMushroomArt className="mb-4" />
          <Text.H1 className="flex-1 text-center text-tertiary-7 mb-4">
            No Results
          </Text.H1>
          <Text.P className="flex-1 text-center text-tertiary-7">
            No available providers match your current criteria.
            <br /> Try updating your preferences.
          </Text.P>
        </div>
      </div>
    );
  }

  if (providers.loadingState === 'done') {
    return (
      <div className="gap-2 text-center">
        {providers.rows.map((p: ProviderBase) => (
          <SearchResultProviderCard
            key={p.npi}
            provider={p}
            onClick={() => onClick(p)}
          />
        ))}
        <Text.P.Bold className="mb-3 mt-5">
          This set of providers was curated just for you.
        </Text.P.Bold>
        Want personalized assistance finding the right fit?{' '}
        <SupportLink text="Contact our scheduling team" />
      </div>
    );
  }
  return null;
};

export default ProviderResults;
