import creditCardType from 'credit-card-type';
import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import { address } from '../../utils/address';
import {
  AuthenticatedState,
  isLoaded,
  Editing,
  ProfileDetails,
  EditableProfileDetails,
  isEditing,
  InsuranceDetails,
  EditableInsuranceDetails,
  CreditCardDetails,
  EditableCreditCardDetails,
  EditingPageData,
} from '../../utils/types';
import { Action } from '../actions';

const editingProfileReducer = produce(
  (
    state: WritableDraft<
      Extract<
        AuthenticatedState['data']['profile'],
        { editState: { mode: 'edit' } }
      >
    >,
    action: Action
  ): void => {
    if (action.type === 'profile/setDetailsPersistence') {
      state.editState.persistence = action.payload;
    } else if (action.type === 'profile/setDetailsPersisted') {
      state.editState.persistence = 'saved';
      Object.assign(state, {
        ...state.editState.edited,
        editState: {
          mode: 'read',
        },
      });
    } else if (action.type === 'profile/setEditedProfileDetail') {
      const updated = {
        ...state.editState.edited,
        ...action.payload,
      };
      state.editState.edited = updated;
    } else if (action.type === 'profile/setEditedProfileDetailAddress') {
      const updated = {
        ...state.editState.edited.address,
        ...action.payload,
      };
      state.editState.edited.address = updated;
    }
  }
);

const editingCreditCardReducer = produce(
  (
    state: WritableDraft<
      Extract<
        AuthenticatedState['data']['creditCard'],
        { editState: { mode: 'edit' } }
      >
    >,
    action: Action
  ): void => {
    if (action.type === 'profile/setEditedCcDetails') {
      const updated = {
        ...state.editState.edited,
        ...action.payload,
      };
      state.editState.edited = updated;
    } else if (action.type === 'profile/creditCard/setDetailsPersistence') {
      state.editState.persistence = action.payload;
    } else if (action.type === 'profile/creditCard/setDetailsPersisted') {
      state.editState.persistence = 'saved';
      state.card_brand = creditCardType(
        state.editState.edited.number!
      )[0]?.niceType;
      state.last_4_digits = state.editState.edited.number!.slice(
        state.editState.edited.number!.length - 4
      );
      [state.expiration_month, state.expiration_year] = (
        state.editState.edited.expiration_date || ''
      ).split('/');
      Object.assign(state, {
        ...state,
        editState: {
          mode: 'read',
        },
      });
    } else if (action.type === 'profile/setEditedBillingAddress') {
      const updated = {
        ...state.editState.edited.billing_address,
        ...action.payload,
      };
      state.editState.edited.billing_address = updated;
    }
  }
);

const editingInsuraceReducer = produce(
  (
    state: WritableDraft<
      Extract<
        AuthenticatedState['data']['insurance'],
        { editState: { mode: 'edit' } }
      >
    >,
    action: Action
  ): void => {
    if (action.type === 'profile/setEditedInsurance') {
      const updated = {
        ...state.editState.edited,
        ...action.payload,
      };
      state.editState.edited = updated;
    } else if (action.type === 'profile/insurance/setFrontOfCard') {
      state.editState.edited.front_of_card = action.payload;
    } else if (action.type === 'profile/insurance/setBackOfCard') {
      state.editState.edited.back_of_card = action.payload;
    } else if (action.type === 'profile/setEditedPaymentMethod') {
      state.editState.edited.payment_method = action.payload;
      state.editState.edited.relationship_to_insured =
        state.editState.edited.relationship_to_insured || 'Self';
    } else if (action.type === 'profile/insurance/setPersistence') {
      state.editState.persistence = action.payload;
    } else if (action.type === 'profile/insurance/setPersisted') {
      Object.assign(state, {
        ...state,
        ...state.editState.edited,
        network_name: action.payload.network_name,
        editState: {
          mode: 'read',
        },
      });
    }
  }
);

const profilePageReducer = produce(
  (state: WritableDraft<AuthenticatedState>, action: Action): void => {
    if (action.type === 'profile/setProfile') {
      const { address, ...rest } = action.payload.details;
      const {
        advanced_md_office_key,
        salesforce_contact_id,
        patient_record_uuid,
        patient_uuid,
      } = action.payload;
      state.data = {
        ...state.data,
        profile: {
          loadingState: 'done',
          address:
            address === null
              ? { street: '', apt_suite: '', city: '', state: '', zip: '' }
              : address,
          ...rest,
          editState: {
            mode: 'read',
          },
        },
        patientData: {
          loadingState: 'done',
          patient_record_uuid,
          patient_uuid,
          advanced_md_office_key,
          salesforce_contact_id,
        },
      };
    } else if (action.type === 'profile/setPaymentInfo') {
      state.data = {
        ...state.data,
        creditCard: {
          loadingState: 'done',
          ...action.payload.creditCard,
          editState: {
            mode: 'read',
          },
        },
        insurance: {
          loadingState: 'done',
          ...action.payload.insurance,
          editState: {
            mode: 'read',
          },
        },
      };
    } else if (action.type === 'profile/setCarriers') {
      state.data.carriers = {
        loadingState: 'done',
        options: action.payload,
      };
    }
    if (state.ui.page.path === 'profile') {
      if (action.type === 'closeMenus') {
        state.ui.page.emailTooltipOpen = false;
        state.ui.page.nameTooltipOpen = false;
        state.ui.page.sexAtBirthTooltipOpen = false;
        state.ui.page.sexOnInsuranceCardTooltipOpen = false;
      }
      if (action.type === 'profile/setNameTooltipOpen') {
        state.ui.page.nameTooltipOpen = action.payload;
      }
      if (action.type === 'profile/setEmailTooltipOpen') {
        state.ui.page.emailTooltipOpen = action.payload;
      }
      if (action.type === 'profile/setSexAtBirthTooltipOpen') {
        state.ui.page.sexAtBirthTooltipOpen = action.payload;
      }
      if (action.type === 'profile/setSexOnInsuranceCardTooltipOpen') {
        state.ui.page.sexOnInsuranceCardTooltipOpen = action.payload;
      }
    }
    if (isLoaded(state.data.profile)) {
      if (action.type === 'profile/details/setEditMode') {
        if (action.payload === 'read') {
          state.data.profile.editState.mode = 'read';
        } else {
          const editState: Editing<
            ProfileDetails,
            EditableProfileDetails
          >['editState'] = {
            mode: 'edit',
            edited: state.data.profile,
            persistence: 'unsaved',
            ecEdit: action.ecEdit,
          };
          state.data.profile.editState = editState;
        }
      }
      if (isEditing(state.data.profile)) {
        state.data.profile = editingProfileReducer(state.data.profile, action);
      }
    }
    if (isLoaded(state.data.insurance)) {
      if (isEditing(state.data.insurance)) {
        state.data.insurance = editingInsuraceReducer(
          state.data.insurance,
          action
        );
      }
      if (action.type === 'profile/insurance/setEditMode') {
        if (action.payload === 'read') {
          state.data.insurance.editState.mode = 'read';
        } else {
          const editState: Editing<
            InsuranceDetails,
            EditableInsuranceDetails
          >['editState'] = {
            mode: 'edit',
            edited: {
              ...state.data.insurance,
              subscriber: {
                address: address(),
                birthdate: '',
                first_name: '',
                last_name: '',
                gender: undefined,
              },
              front_of_card: undefined,
              back_of_card: undefined,
            },
            persistence: 'unsaved',
          };
          state.data.insurance.editState = editState;
        }
      }
    }
    if (isLoaded(state.data.creditCard)) {
      if (action.type === 'profile/creditCard/setEditMode') {
        if (action.payload === 'read') {
          state.data.creditCard.editState.mode = 'read';
        } else {
          const editState: Editing<
            CreditCardDetails,
            EditableCreditCardDetails
          >['editState'] = {
            mode: 'edit',
            edited: {
              ...state.data.creditCard,
              number: '',
              billing_address: address(),
              first_name: '',
              last_name: '',
              cvv: '',
              expiration_date: '',
            },
            persistence: 'unsaved',
          };
          state.data.creditCard.editState = editState;
        }
      }
      if (isEditing(state.data.creditCard)) {
        state.data.creditCard = editingCreditCardReducer(
          state.data.creditCard,
          action
        );
      }
    }
    if (action.type === 'profile/setInvalid') {
      if (isLoaded(state.data[action.payload.key])) {
        (
          state.data[
            action.payload.key
          ] as EditingPageData[typeof action.payload.key]
        ).editState.invalid = action.payload.invalid;
      }
    }
  }
);

export default profilePageReducer;
