import { ActiveProviderShapeV3, InactiveProviderShape } from '@/utils/types';
import ProviderCardV2, { ProviderCardV2Props } from './ProviderCardV2';
import Text from '@/components/Text';
import { therapyTypeToVisitType } from '@/utils/constants';
import useSortedProvidersAndHeadings from '@/utils/use-sort-providers-and-headings';

interface CareTeamTabProps {
  providers: (ActiveProviderShapeV3 | InactiveProviderShape)[];
  active: boolean;
}

const CareTeamTab = ({ providers, active }: CareTeamTabProps) => {
  const { providersAndHeadings } = useSortedProvidersAndHeadings(
    providers,
    active
  );

  return (
    <>
      <div className="space-y-4 basis-full w-full">
        {providersAndHeadings
          .sort((a, b) =>
            a.heading.localeCompare(b.heading, undefined, {
              sensitivity: 'base',
            })
          )
          .map(({ heading, providers }) => {
            return (
              <div key={heading}>
                <div className="bg-gray-100 mb-3 p-3 rounded-3">
                  <Text.P.Bold>{therapyTypeToVisitType[heading]}</Text.P.Bold>
                </div>
                <div>
                  {providers.map((provider) => {
                    const props: ProviderCardV2Props = {
                      provider,
                      active,
                      therapyType: heading,
                    };
                    return <ProviderCardV2 key={provider.npi} {...props} />;
                  })}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default CareTeamTab;
