import { useContext } from 'react';
import { StateContext } from '../App';
import Text from '../components/Text';
import CreditCardDetails from './profile/CreditCardDetails';
import EmergencyContactDetails from './profile/EmergencyContactDetails';
import Details from './profile/Details';
import Insurance from './profile/Insurance';
import actions from '../state/actions';
import useData from '../state/use-data';

const Profile = () => {
  const { state, dispatch } = useContext(StateContext);

  const { data, WithData } = useData([
    'profile',
    'insurance',
    'patientData',
    'creditCard',
    'carriers',
    'selfPayRates',
  ]);

  if (state.ui.page.path !== 'profile') {
    return null;
  }
  const pageState = state.ui.page;

  return (
    <WithData data={data}>
      {({ profile, patientData, insurance, carriers, creditCard }) => (
        <div className="space-y-4 w-full relative">
          <Text.H1>Profile settings</Text.H1>
          <Details
            details={profile}
            patientData={patientData}
            pageState={pageState}
          />
          <EmergencyContactDetails
            details={profile}
            patientData={patientData}
            pageState={pageState}
          />
          <Insurance
            insurance={insurance}
            carriers={carriers.options}
            patientData={patientData}
            pageState={pageState}
          />
          <CreditCardDetails
            creditCard={creditCard}
            patientData={patientData}
            homeAddress={profile.address}
            setEditing={(editing: boolean) =>
              dispatch(actions.profile.setCcEditMode(editing ? 'edit' : 'read'))
            }
          />
        </div>
      )}
    </WithData>
  );
};

export default Profile;
