import Text from '@/components/Text';
import { groupBillingStatements } from '@/utils/billing';
import { useMemo } from 'react';
import { Loaded, Billing as BillingData } from '@/utils/types';
import dayjs from 'dayjs';

interface BillingStatementsProps {
  billing: Loaded<BillingData>;
}

const StatementsTab = ({ billing }: BillingStatementsProps) => {
  const groupedStatements = useMemo(() => {
    return groupBillingStatements(billing);
  }, [billing]);

  return (
    <>
      <Text.P className="mb-5 text-tertiary-5 mt-2">
        Statements indicate past appointments. Some may take up to a week to
        appear, and adjustments can occur based on your insurance coverage.
      </Text.P>
      <div className="pt-3 pb-3 w-full flex justify-between border-b-1 border-tertiary-2">
        <div className="w-1/2 flex justify-between items-center">
          <Text.P.Bold className="flex-1">Appointment date</Text.P.Bold>
          <Text.P.Bold className="flex-1 hidden md:block">Provider</Text.P.Bold>
        </div>
      </div>
      {Object.keys(groupedStatements).map((date, i) => (
        <div
          className="py-4 w-full flex justify-between border-b-1 border-tertiary-2"
          key={i}
        >
          <div className="w-1/2 flex justify-between items-center flex-wrap-reverse md:flex-nowrap gap-y-1 md:gap-y-0">
            <Text.P className="md:flex-1 md:min-w-max min-w-full">
              {dayjs(date).format('MM/DD/YYYY')}
            </Text.P>
            <Text.P className="md:flex-1 font-semibold md:font-normal md:min-w-max min-w-full">
              {groupedStatements[date].providers.sort().join(', ')}
            </Text.P>
          </div>
          <div className="w-1/2 flex justify-end items-center">
            <a
              className="cursor-pointer text-primary-3 no-underline"
              href={groupedStatements[date].link}
              target="_blank"
              rel="noopener"
            >
              Download<span className="hidden md:inline"> statement</span>
            </a>
          </div>
        </div>
      ))}
    </>
  );
};

export default StatementsTab;
