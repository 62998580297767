import { useContext, useCallback, useMemo } from 'react';
import { isInCurrentBusinessWeekAndFuture } from '@/utils/dates';
import dayjs from 'dayjs';
import { ProviderBase } from '@/utils/types';
import Text from '@/components/Text';
import ProviderHeadshot from '@/pages/care-team/ProviderHeadshot';
import stopPropagation from '@/utils/stop-propagation';
import {
  providerLicenses,
  providerLicenseDescriptions,
  PROVIDER_BIO_MAX_LENGTH,
} from '@/utils/constants';
import { StateContext, MobileContext } from '@/App';
import InfoCircle from '@/components/icons/InfoCircle';
import WithPageState from '@/pages/WithPageState';
import actions from '@/state/actions';
import InfoCard from '@/components/InfoCard';
import CheckCircle from '@/components/icons/CheckCircle';
import { twMerge } from 'tailwind-merge';

function SearchResultProviderCard(props: {
  provider: ProviderBase;
  onClick: () => unknown;
}) {
  const {
    onClick,
    provider: {
      first_name,
      last_name,
      profile_bio,
      profile_summary,
      slot_start_time,
      profile_image_s3_url: profileImage,
      licenses,
      npi,
    },
  } = props;

  const { dispatch } = useContext(StateContext);
  const mobile = useContext(MobileContext);

  const nextScheduledAppointment = useMemo(() => {
    if (slot_start_time) {
      const date = dayjs(slot_start_time);
      return isInCurrentBusinessWeekAndFuture(date)
        ? 'This week'
        : date.format('dddd, MMM Do');
    }
    return null;
  }, [slot_start_time]);

  const profileBio = useMemo(() => {
    const bio = profile_summary || profile_bio;
    if (bio.length > PROVIDER_BIO_MAX_LENGTH) {
      return `${bio.substring(0, PROVIDER_BIO_MAX_LENGTH)}...`;
    }
    return bio;
  }, [profile_bio, profile_summary]);

  const toggleToolTip = (open: boolean, providerNpi: null | number) => {
    dispatch(
      actions.careTeam.setLicenseTooltipOpen({
        open,
        page: 'root',
        activeTooltipNpi: providerNpi,
      })
    );
  };

  return (
    <WithPageState page="care-team">
      {({ pageState }) => {
        const shouldShowToolTip =
          pageState.root.licenseTooltipOpen &&
          pageState.root.activeTooltipNpi === npi;

        const ToolTipDisplay = useCallback(() => {
          if (!shouldShowToolTip) return null;
          return (
            <div
              onMouseLeave={
                mobile ? undefined : () => toggleToolTip(false, null)
              }
              className={twMerge(
                'flex-grow z-10 absolute items-center left-[0] max-w-prose',
                'translate-y-[calc(-100%_-_2rem)] md:translate-y-0 md:top-0 md:left-0 md:w-max mb-2 md:mb-0'
              )}
            >
              <InfoCard>
                <Text.P>
                  {
                    providerLicenseDescriptions[licenses[0].toUpperCase()]
                      .description
                  }
                </Text.P>
              </InfoCard>
            </div>
          );
        }, [shouldShowToolTip]);

        return (
          <div className="w-full pb-4 flex lg:pl-8">
            <div
              className="border-1 border-tertiary-2 rounded-2 w-full p-4 flex shadow-lg cursor-pointer"
              onClick={onClick}
            >
              <div
                className={twMerge(
                  'flex-col items-center justify-center w-full gap-x-6 flex flex-wrap',
                  'md:flex-row',
                  'lg:justify-start'
                )}
                onMouseLeave={
                  mobile ? undefined : () => toggleToolTip(false, null)
                }
              >
                <div className="flex-none ">
                  <ProviderHeadshot
                    profileImage={profileImage}
                    className="rounded-circle object-cover w-[172px] h-[172px]"
                  />
                </div>

                <div className="flex flex-col items-center flex-1 relative min-w-[220px] w-full md:items-start">
                  <Text.Small
                    className={twMerge(
                      'flex flex-col flex-nowrap justify-center text-tertiary-5 mt-4 mb-2 w-full ',
                      'md:flex-row md:flex-wrap md:justify-between md:m-0'
                    )}
                  >
                    <div
                      className={twMerge(
                        'order-3 mt-0 flex-none',
                        'md:mt-2 md:order-none'
                      )}
                    >
                      <Text.P className="text-ellipsis overflow-hidden whitespace-nowrap md:whitespace-normal mx-auto md:mx-[unset]">
                        {providerLicenses[licenses[0]]?.name && (
                          <InfoCircle.Small
                            className="inline"
                            onClick={stopPropagation((e) => {
                              e.preventDefault();
                              const providerNpi = pageState.root
                                .licenseTooltipOpen
                                ? null
                                : npi;
                              toggleToolTip(
                                !pageState.root.licenseTooltipOpen,
                                providerNpi
                              );
                            })}
                            onMouseEnter={
                              mobile
                                ? undefined
                                : () => toggleToolTip(true, npi)
                            }
                          />
                        )}
                        {providerLicenses[licenses[0]]?.name || ''}
                      </Text.P>
                      <ToolTipDisplay />
                    </div>
                    <div
                      className={twMerge(
                        'order-1 flex-none mb-4 bg-secondary-0 p-[4px] px-3 text-secondary-5 text-sm font-sm rounded-sm duration-200 w-fit mx-auto',
                        'md:mb-0 md:order-none md:mx-0'
                      )}
                      style={{ borderRadius: '2rem' }}
                    >
                      <CheckCircle.Small
                        className="inline-block secondary-5 mr-2"
                        stroke={'secondary-5'}
                      />
                      Accepting Patients
                    </div>

                    <Text.H2
                      className={twMerge(
                        'order-2 text-tertiary-7 mb-[4px]',
                        'md:mb-4 md:w-full md:text-left md:order-none'
                      )}
                    >
                      {first_name} {last_name}
                    </Text.H2>
                  </Text.Small>

                  <Text.P className="text-tertiary-7 flex line-clamp-4 text-overflow-ellipsis self-start text-left">
                    {profileBio}
                  </Text.P>

                  <div className="w-full h-[1px] bg-tertiary-2 mt-4 mb-4" />

                  <Text.P className="text-tertiary-5 flex self-start">
                    <Text.P.Inline.Bold className="text-secondary-4 inline mr-[4px]">
                      Next appointment
                    </Text.P.Inline.Bold>{' '}
                    {nextScheduledAppointment}
                  </Text.P>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </WithPageState>
  );
}

export default SearchResultProviderCard;
