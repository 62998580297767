import produce from 'immer';
import { WritableDraft } from 'immer/dist/types/types-external';
import { AuthenticatedState } from '../../utils/types';
import { Action } from '../actions';

const billingPageReducer = produce(
  (state: WritableDraft<AuthenticatedState>, action: Action) => {
    if (action.type === 'billing/setBilling') {
      state.data.billing = {
        loadingState: 'done',
        ...action.payload,
      };
    }
    if (state.ui.page.path === 'billing') {
      if (action.type === 'billing/setBillingTooltipOpen') {
        state.ui.page.billingTooltipOpen = action.payload;
      }
      if (action.type === 'closeMenus') {
        state.ui.page.billingTooltipOpen = false;
      }
    }
  }
);

export default billingPageReducer;
