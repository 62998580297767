import Icon, { IconProps } from './Icon';

const Phone = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M13.8214 6.57143C14.6935 6.74158 15.4949 7.16809 16.1232 7.79637C16.7515 8.42465 17.178 9.22612 17.3482 10.0982M13.8214 3C15.6332 3.20129 17.3229 4.01265 18.6127 5.3009C19.9026 6.58914 20.7161 8.27768 20.9196 10.0893M10.408 13.592C9.33515 12.5192 8.48801 11.3061 7.8666 10.0118C7.81314 9.90048 7.78642 9.84481 7.76588 9.77437C7.69292 9.52406 7.74533 9.21668 7.89712 9.00469C7.93984 8.94504 7.99087 8.89401 8.09293 8.79194C8.40508 8.47979 8.56116 8.32372 8.66319 8.16677C9.04801 7.57491 9.04801 6.81189 8.66319 6.22003C8.56116 6.06308 8.40508 5.90701 8.09293 5.59486L7.91894 5.42086C7.44444 4.94637 7.20718 4.70912 6.95238 4.58024C6.44563 4.32393 5.84719 4.32393 5.34044 4.58024C5.08563 4.70912 4.84837 4.94637 4.37387 5.42086L4.23313 5.56161C3.76025 6.03449 3.52381 6.27093 3.34323 6.59239C3.14286 6.94909 2.99878 7.5031 3.00001 7.91223C3.0011 8.28093 3.07262 8.53292 3.21567 9.03688C3.98439 11.7453 5.43481 14.301 7.56693 16.433C9.69906 18.5652 12.2547 20.0156 14.9631 20.7844C15.4671 20.9274 15.7191 20.9989 16.0878 21C16.4969 21.0012 17.0509 20.8571 17.4076 20.6568C17.7291 20.4762 17.9655 20.2397 18.4384 19.7669L18.5791 19.6262C19.0537 19.1516 19.2909 18.9144 19.4197 18.6595C19.6761 18.1529 19.6761 17.5544 19.4197 17.0476C19.2909 16.7928 19.0537 16.5555 18.5791 16.0811L18.4052 15.907C18.093 15.5949 17.937 15.4388 17.78 15.3368C17.1881 14.952 16.4251 14.952 15.8332 15.3368C15.6763 15.4388 15.5202 15.5949 15.208 15.907C15.106 16.0091 15.055 16.0602 14.9953 16.1029C14.7833 16.2547 14.476 16.3071 14.2256 16.2341C14.1552 16.2136 14.0996 16.1869 13.9882 16.1334C12.6939 15.512 11.4808 14.6648 10.408 13.592Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default Phone;
