import Icon, { IconProps } from './Icon';

const X = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M13 1L1 13M1 1L13 13"
        transform="translate(5,5)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default X;
