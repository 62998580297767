import { IconProps } from './icons/Icon';
import Text from './Text';

interface NavItemProps {
  label: string;
  Icon: (p: IconProps) => React.ReactElement;
  active: boolean;
  onClick: () => unknown;
  badge?: React.ReactNode;
}

const NavItem = ({ label, Icon, active, onClick, badge }: NavItemProps) => {
  return (
    <li
      className={`${
        active ? 'text-primary-3' : 'text-tertiary-5'
      } cursor-pointer flex items-center h-10`}
      onClick={onClick}
    >
      <div
        className={`w-1 ${
          active ? 'bg-primary-3 h-full rounded-tr-5 rounded-br-5' : ''
        }`}
      ></div>
      <Icon
        className="mr-5 ml-4"
        stroke={active ? 'primary-3' : 'tertiary-5'}
      />
      <Text.P.Bold>{label}</Text.P.Bold>
      {badge ? (
        <div aria-label="badge" className="ml-auto mr-2">
          {badge}
        </div>
      ) : null}
    </li>
  );
};

export default NavItem;
