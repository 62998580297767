import Icon, { IconProps } from './Icon';

const HiddenEye = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        d="M7.95245 3.2436C8.29113 3.19353 8.64051 3.16667 9.00035 3.16667C13.2545 3.16667 16.0461 6.9207 16.9839 8.40569C17.0974 8.58542 17.1542 8.67528 17.1859 8.81389C17.2098 8.91799 17.2098 9.08222 17.1859 9.18631C17.1541 9.32492 17.097 9.41538 16.9827 9.59631C16.7328 9.99179 16.3518 10.5476 15.8471 11.1504M4.6036 4.59586C2.80187 5.81808 1.57871 7.51615 1.01759 8.4044C0.903571 8.58489 0.846562 8.67514 0.814783 8.81373C0.790914 8.91783 0.790905 9.08203 0.814761 9.18613C0.846525 9.32473 0.903276 9.41459 1.01678 9.59432C1.95462 11.0793 4.74618 14.8333 9.00035 14.8333C10.7157 14.8333 12.1932 14.223 13.4073 13.3972M1.50035 1.5L16.5003 16.5M7.23258 7.23223C6.78017 7.68464 6.50035 8.30964 6.50035 9C6.50035 10.3807 7.61963 11.5 9.00035 11.5C9.6907 11.5 10.3157 11.2202 10.7681 10.7678"
        stroke="#5937E2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default HiddenEye;
