import { ChangeEvent } from 'react';

export interface DropdownProps<T extends string> {
  options: Array<{
    key: string;
    value: T | undefined;
    disabled?: boolean;
  }>;
  value: T | undefined;
  onChange: (v: T) => unknown;
  state?: 'error' | '' | undefined;
  id?: string | undefined;
}

function Dropdown<T extends string>({
  options,
  value,
  state,
  onChange,
  id,
}: DropdownProps<T>) {
  if (!options.find(({ value: opt }) => opt === value)) {
    options = [{ key: '', value: undefined }, ...options];
  }
  const borderColor =
    state === 'error' ? 'border-warning-1' : 'border-tertiary-3';
  return (
    <select
      className={`w-full h-[50px] border-1 rounded pt-3 pr-2 pb-3 pl-2 text-tertiary-6 bg-tertiary-0 ${borderColor}`}
      value={value}
      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
        onChange(e.target.value as T)
      }
      id={id}
    >
      {options.map(({ key, value, disabled }) => (
        <option key={value} value={value} disabled={disabled}>
          {key}
        </option>
      ))}
    </select>
  );
}

export default Dropdown;
