import { useNavigate } from 'react-router-dom';
import Button from '@/components/Button';
import X from '@/components/icons/X';
import Text from '@/components/Text';
import actions, { Action } from '@/state/actions';
import { Dispatch } from 'react';

export type RescheduleFeeWarningModalProps = {
  rescheduleURL?: string;
  cancellationFee: string;
  dispatch: Dispatch<Action>;
  trackEvent?: (name: string) => void;
  onCancel?: () => void;
};

const RescheduleFeeWarningModal = ({
  rescheduleURL,
  cancellationFee,
  dispatch,
  trackEvent,
  onCancel,
}: RescheduleFeeWarningModalProps) => {
  const navigate = useNavigate();
  const closeModal = () => {
    dispatch(actions.setModal(null));
  };

  return (
    <div className="rounded-2 p-6 space-y-6 bg-tertiary-0 max-w-[320px] md:max-w-[520px]">
      <div className="flex justify-between align-top">
        <Text.H4 className="text-[15px] md:text-[18px]">
          Confirm last-minute reschedule fee
        </Text.H4>
        <X onClick={closeModal} className="cursor-pointer"></X>
      </div>
      <div className="space-y-2 text-tertiary-5 whitespace-pre-wrap md:space-y-3">
        <Text.P.Bold>
          Your appointment is less than 24 hours away so you'll be charged a{' '}
          {cancellationFee} rescheduling fee
        </Text.P.Bold>
        <Text.P>Are you sure you'd like to reschedule this appointment?</Text.P>
      </div>
      <div className="flex flex-col space-y-2 max-w-screen-sm md:flex-row md:space-x-2 md:space-y-0">
        <Button
          onClick={() => {
            closeModal();
            onCancel?.();
          }}
          sizeClasses="w-full md:w-[230px]"
          size="large"
        >
          No
        </Button>
        <Button
          onClick={() => {
            trackEvent?.('confirm_last_minute_reschedule_button_clicked');
            if (rescheduleURL) {
              navigate(rescheduleURL);
            } else {
              closeModal();
            }
          }}
          variant="primary"
          size="large"
          sizeClasses="w-full md:w-[230px]"
        >
          Yes
        </Button>
      </div>
    </div>
  );
};

export default RescheduleFeeWarningModal;
