import Icon, { IconProps } from './Icon';

const RightArrow = (props: IconProps) => {
  return (
    <Icon width="18" height="14" viewBox="0 0 18 14" {...props}>
      <path
        d="M1 7H17M17 7L11 1M17 7L11 13"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default RightArrow;
