import {
  useNavigate,
  To,
  RelativeRoutingType,
  useSearchParams,
} from 'react-router-dom';
import Button from './Button';
import Chevron from './icons/Chevron';

interface NavigateOptions {
  to: To;
  options?: {
    replace?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    state?: any;
    relative?: RelativeRoutingType;
  };
}
export interface BackNavigationProps {
  navigateOptions?: NavigateOptions;
}

const BackNavigation = ({ navigateOptions }: BackNavigationProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { to, options } = navigateOptions || {};
  const isDeeplink = searchParams.get('deeplink');

  const handleClick = () => {
    if (to) {
      navigate(to, options);
      return;
    } else if (searchParams.get('deeplink')) {
      navigate('/');
    } else {
      navigate(-1);
    }
  };

  const text = isDeeplink ? 'Back home' : 'Back';
  return (
    <Button
      variant="flat"
      onClick={handleClick}
      renderIcon={() => (
        <Chevron className="group-hover:stroke-primary-3 mx-[-7.5px] rotate-90" />
      )}
      size="medium-no-px"
    >
      {text}
    </Button>
  );
};

export default BackNavigation;
