import Icon, { IconProps } from './Icon';

const TwoPeople = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        d="M17 3.46776C18.4817 4.20411 19.5 5.73314 19.5 7.5C19.5 9.26686 18.4817 10.7959 17 11.5322M19 16.7664C20.5115 17.4503 21.8725 18.565 23 20M3 20C4.94649 17.5226 7.58918 16 10.5 16C13.4108 16 16.0535 17.5226 18 20M15 7.5C15 9.98528 12.9853 12 10.5 12C8.01472 12 6 9.98528 6 7.5C6 5.01472 8.01472 3 10.5 3C12.9853 3 15 5.01472 15 7.5Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default TwoPeople;
