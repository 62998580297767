import Text from './Text';
import { ReactChildren } from '@/utils/types';

export interface RadioButtonProps {
  onCheck: () => unknown;
  label: string;
  name: string;
  selectedValue: string;
  value: string;
  radioContent?: ReactChildren;
  contentPositionClass?: string;
  disabled?: boolean;
}

const getBorderColor = (disabled: boolean) => {
  return disabled ? 'border-tertiary-2' : 'border-tertiary-4';
};

export function RadioButton({
  onCheck,
  label,
  name,
  selectedValue,
  value,
  radioContent,
  contentPositionClass = 'items-center',
  disabled = false,
}: RadioButtonProps) {
  const checked = selectedValue === value;

  return (
    <label className="flex gap-3 items-center cursor-pointer">
      <input
        type="radio"
        className="sr-only peer"
        name={name}
        value={value}
        onChange={onCheck}
        checked={checked}
        disabled={disabled}
      />
      <div
        className={`${getBorderColor(
          disabled
        )} rounded-circle h-[18px] w-[18px] min-w-[18px] flex justify-center items-center  border-1 peer-focus:ring-2 peer-focus:ring-primary-4 peer-focus:ring-offset-11`}
      >
        {checked && (
          <div className="rounded-circle h-[10px] w-[10px] bg-primary-4"></div>
        )}
      </div>
      <div className={`flex flex-col ${contentPositionClass}`}>
        {radioContent || (
          <Text.P.Inline className="text-tertiary-6">{label}</Text.P.Inline>
        )}
      </div>
    </label>
  );
}

export default RadioButton;
