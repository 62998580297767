import { daysOfWeek, timeSlots } from './constants';
import { Availability, AvailabilityParam } from './types';

export default (availability?: Availability): AvailabilityParam =>
  daysOfWeek.reduce((o, d) => {
    if (
      !availability ||
      availability.days === 'any' ||
      !availability.days.length ||
      availability.days.includes(d)
    ) {
      o[d] = (['Morning', 'Afternoon', 'Evening'] as const).reduce((a, s) => {
        if (
          !availability ||
          availability.preferredTimes === 'any' ||
          !Object.values(availability.preferredTimes).length ||
          availability.preferredTimes?.[d]?.find((slot) => slot === s)
        ) {
          a.push(timeSlots[s]);
        }
        return a;
      }, [] as AvailabilityParam[typeof daysOfWeek[number]]);
    } else {
      o[d] = [];
    }
    return o;
  }, {} as AvailabilityParam);
