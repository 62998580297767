import React from 'react';
import { isInteger } from './validations';

export const changeEventWrapper =
  (onChange: ((v: string) => unknown) | undefined) =>
  (e: React.ChangeEvent<HTMLInputElement>) =>
    onChange && onChange(e.target.value);

export const changeOnlyIfValid =
  (validate: ((v: string) => boolean) | Array<(v: string) => boolean>) =>
  (onChange: (v: string) => unknown) =>
  (v: string) => {
    if (
      Array.isArray(validate)
        ? validate.reduce((valid, f) => valid && f(v), true)
        : validate(v)
    ) {
      onChange(v);
    }
  };

export const changeOnlyIfInteger = changeOnlyIfValid(isInteger);
