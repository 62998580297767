import X from '../../components/icons/X';
import Text from '../../components/Text';
import {
  psychiatryProviderRole,
  willingToSeeToTherapyType,
} from '../../utils/constants';
import { makeCorrectCasing } from '../../utils/format';
import { ProviderDetail } from '../../utils/types';

export interface SeeAllSpecialtiesModalProps {
  onClose: () => unknown;
  provider: ProviderDetail;
}

const SeeAllSpecialtiesModal = ({
  onClose,
  provider,
}: SeeAllSpecialtiesModalProps) => {
  return (
    <div className="rounded-2 bg-tertiary-0 shadow-modal">
      <div className="flex justify-between p-6 min-w-96 shadow-modal-header">
        <Text.H4>Specialities</Text.H4>
        <X onClick={onClose} className="cursor-pointer"></X>
      </div>
      <div className="px-6 overflow-auto max-h-[80vh]">
        <Text.P.Bold className="pt-4">
          {provider.role === psychiatryProviderRole
            ? 'Provider Type'
            : 'Therapy types'}
        </Text.P.Bold>
        <ul className="mb-7">
          {provider.role === psychiatryProviderRole ? (
            <li>Psychiatry</li>
          ) : (
            provider.willing_to_see.map((patientType) => (
              <li>{willingToSeeToTherapyType[patientType]}</li>
            ))
          )}
        </ul>

        <Text.P.Bold className="pt-4">Methods</Text.P.Bold>
        <ul className="mb-7">
          {[...new Set([...provider.top_modalities, ...provider.modalities])]
            .sort()
            .map((modality) => (
              <li className="list-none">{makeCorrectCasing(modality)}</li>
            ))}
        </ul>

        <Text.P.Bold className="pt-4">Focus Areas</Text.P.Bold>
        <ul className="mb-7">
          {[
            ...new Set([
              ...provider.top_specializations,
              ...provider.specializations,
            ]),
          ]
            .sort()
            .map((specialization) => (
              <li className="list-none">{makeCorrectCasing(specialization)}</li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default SeeAllSpecialtiesModal;
