import {
  CareTeamPageState,
  IdentityFilters,
  ProviderDetail,
  ServerData,
  ToolTipActionPayload,
} from '@/utils/types';

export default {
  setLicenseTooltipOpen: (payload: ToolTipActionPayload) =>
    ({
      type: 'careTeam/setLicenseTooltipOpen',
      payload,
    } as const),
  setPracticeTooltipOpen: (payload: ToolTipActionPayload) =>
    ({
      type: 'careTeam/setPracticeTooltipOpen',
      payload,
    } as const),
  setAvailability: (payload: CareTeamPageState['rematch']['availability']) =>
    ({
      type: 'careTeam/setAvailability',
      payload,
    } as const),
  setAvailabilityPickerOpen: (payload: boolean) =>
    ({
      type: 'careTeam/setAvailabilityPickerOpen',
      payload,
    } as const),
  setSpecializationFilter: (payload: string | undefined) =>
    ({ type: 'careTeam/setSpecializationFilter', payload } as const),
  setModalityFilter: (payload: string | undefined) =>
    ({ type: 'careTeam/setModalityFilter', payload } as const),
  setIdentityFilters: (payload: IdentityFilters) =>
    ({ type: 'careTeam/setIdentityFilters', payload } as const),
  setRematchProviders: (payload: ProviderDetail[]) =>
    ({ type: 'careTeam/setRematchProviders', payload } as const),
  setRematchProvidersLoadingState: (
    loadingState: ServerData<unknown>['loadingState']
  ) =>
    ({
      type: 'careTeam/setRematchProvidersLoadingState',
      payload: loadingState,
    } as const),
  setRematchProviderToReplace: (payload: number | undefined) =>
    ({
      type: 'careTeam/setRematchProviderToReplace',
      payload,
    } as const),
};
