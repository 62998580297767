import Text from '@/components/Text';

interface EmptyStatePageProps {
  icon?: JSX.Element;
  header: string;
  message: JSX.Element;
}

const EmptyStatePage = ({ icon, header, message }: EmptyStatePageProps) => {
  return (
    <div className="flex flex-col gap-8 w-full h-full items-center justify-center text-center mt-10">
      <div className="flex flex-col gap-4 items-center">
        {icon}
        <Text.H2>{header}</Text.H2>
        {message}
      </div>
    </div>
  );
};

export default EmptyStatePage;
