import useData from '@/state/use-data';
import { ProviderWithFirstAppointmentInfo, ReactChildren } from '@/utils/types';

const WithProviderFromCareTeamV2 = ({
  providerNpi,
  children,
}: {
  providerNpi?: number | string | undefined;
  children: (props: {
    provider?: ProviderWithFirstAppointmentInfo;
  }) => ReactChildren;
}) => {
  const { data, WithData } = useData(['careTeam_v3']);

  return (
    <WithData data={data}>
      {({ careTeam_v3: careTeam }) => {
        let provider;
        if (providerNpi) {
          const npi = Number(providerNpi);
          provider = [...careTeam.active, ...careTeam.inactive].find(
            (p) => p.npi === npi
          );
        }
        return children({ provider });
      }}
    </WithData>
  );
};

export default WithProviderFromCareTeamV2;
