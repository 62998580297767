import ProviderHeadshot from '@/pages/care-team/ProviderHeadshot';
import Text from '@/components/Text';
import {
  providerLicenseDescriptions,
  providerLicenses,
} from '@/utils/constants';
import InfoCircle from '@/components/icons/InfoCircle';
import stopPropagation from '@/utils/stop-propagation';
import InfoCard from '@/components/InfoCard';
import { CareTeamPageState, ProviderDetail } from '@/utils/types';
import actions from '@/state/actions';
import { useContext } from 'react';
import { MobileContext, StateContext } from '@/App';

interface Props {
  pageState: CareTeamPageState;
  provider: ProviderDetail;
}
export function ProviderLockup({ pageState, provider }: Props) {
  const { dispatch } = useContext(StateContext);
  const mobile = useContext(MobileContext);
  const toggleToolTip = (open: boolean, providerNpi: null | number) => {
    dispatch(
      actions.careTeam.setLicenseTooltipOpen({
        open,
        page: 'root',
        activeTooltipNpi: providerNpi,
      })
    );
  };
  const shouldShowToolTip =
    pageState.root.licenseTooltipOpen &&
    pageState.root.activeTooltipNpi === provider.npi;

  return (
    <div className="p-4 flex items-center gap-x-6 flex-col md:flex-row gap-1 md:gap-x-6 md:h-[128px]">
      <ProviderHeadshot
        profileImage={provider.profile_image_s3_url}
        className="w-20 h-20"
      />
      <div className="items-center h-20 flex flex-col justify-center md:items-start w-full ">
        <Text.H3 className="overflow-hidden text-ellipsis line-clamp-1">
          {provider.first_name} {provider.last_name}
        </Text.H3>
        <div className="text-center relative md:text-left inline-flex w-full  ">
          <Text.P className="text-ellipsis overflow-hidden whitespace-nowrap md:whitespace-normal mx-auto md:mx-[unset]">
            {providerLicenses[provider.licenses[0]]?.name && (
              <InfoCircle.Small
                className="inline"
                onClick={stopPropagation((e) => {
                  e.preventDefault();
                  const providerNpi = pageState.root.licenseTooltipOpen
                    ? null
                    : provider.npi;
                  toggleToolTip(
                    !pageState.root.licenseTooltipOpen,
                    providerNpi
                  );
                })}
                onMouseEnter={
                  mobile ? undefined : () => toggleToolTip(true, provider.npi)
                }
              />
            )}
            {providerLicenses[provider.licenses[0]]?.name || ''}
          </Text.P>
          {shouldShowToolTip && (
            <div
              onMouseLeave={
                mobile ? undefined : () => toggleToolTip(false, null)
              }
              className="flex-grow z-10 absolute translate-y-[calc(-100%)] md:translate-y-0 md:top-0  left-[0]   md:w-max max-w-prose mb-2 md:mb-0 items-center"
            >
              <InfoCard>
                <Text.P>
                  {
                    providerLicenseDescriptions[
                      provider.licenses[0].toUpperCase()
                    ].description
                  }
                </Text.P>
              </InfoCard>
            </div>
          )}
        </div>

        {/* TODO: handle multiple licenses */}
      </div>
    </div>
  );
}
