import actions, { Action } from '@/state/actions';
import { Dispatch } from 'react';

const useRefreshCareTeam = (dispatch: Dispatch<Action>) => {
  const refreshCareTeam = () => {
    dispatch(
      actions.async.setLoading({
        key: 'appointments_v2',
        loadingState: 'needed',
      })
    );
    dispatch(
      actions.async.setLoading({
        key: 'careTeam_v3',
        loadingState: 'needed',
      })
    );
    // to do, once we move care-team derivation to just use appointments_v2, I don't think we will need patientEvents anymore
    dispatch(
      actions.async.setLoading({
        key: 'patientEvents',
        loadingState: 'needed',
      })
    );
  };
  return refreshCareTeam;
};

export default useRefreshCareTeam;
