import Icon, { IconProps } from './Icon';

const Ellipsis = (props: IconProps) => {
  return (
    <Icon width="20" height="20" viewBox="0 0 20 20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99984 10.0007C4.99984 10.9211 4.25365 11.6673 3.33317 11.6673C2.4127 11.6673 1.6665 10.9211 1.6665 10.0007C1.6665 9.08018 2.4127 8.33398 3.33317 8.33398C4.25365 8.33398 4.99984 9.08018 4.99984 10.0007ZM11.6665 10.0007C11.6665 10.9211 10.9203 11.6673 9.99984 11.6673C9.07936 11.6673 8.33317 10.9211 8.33317 10.0007C8.33317 9.08018 9.07936 8.33398 9.99984 8.33398C10.9203 8.33398 11.6665 9.08018 11.6665 10.0007ZM16.6665 11.6673C17.587 11.6673 18.3332 10.9211 18.3332 10.0007C18.3332 9.08018 17.587 8.33398 16.6665 8.33398C15.746 8.33398 14.9998 9.08018 14.9998 10.0007C14.9998 10.9211 15.746 11.6673 16.6665 11.6673Z"
        fill="#262626"
      />
    </Icon>
  );
};

export default Ellipsis;
