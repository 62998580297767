import EmptyStatePage from './EmptyStatePage';
import Text from '@/components/Text';
import Plant from '@/components/assets/Plant';
import Button from '@/components/Button';
import SupportLink from '@/components/SupportLink';

const ErrorMessage = () => (
  <Text.P className="text-tertiary-5">
    Try checking the URL or refreshing the page. If this problem persists,
    please contact: <SupportLink />
  </Text.P>
);

const ErrorPage = () => {
  return (
    <div className="flex flex-col gap-8 w-full h-full items-center justify-center text-center">
      <EmptyStatePage
        icon={<Plant />}
        header="Hm, something went wrong."
        message={<ErrorMessage />}
      />
      <Button onClick={() => window.location.reload()}>Refresh page</Button>
    </div>
  );
};

export default ErrorPage;
