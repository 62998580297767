import Text from '@/components/Text';
import { therapyTypeToInitialAppointmentDescription } from '@/utils/appointments';
import { OldAppointmentProps, TherapyType } from '@/utils/types';
import User from '@/components/icons/User';
import dayjs from 'dayjs';
import Clock from '../icons/Clock';
import Video from '../icons/Video';
import Calendar from '../icons/Calendar';

type OldAppointmentCardProps = OldAppointmentProps & {
  therapyType: TherapyType;
};

// For display in reschedule and rematch flows
const OldAppointmentCard = ({
  startTime,
  isFirstAppointment,
  therapyType,
  appointmentType,
  previousLengthInMinutes,
}: OldAppointmentCardProps) => {
  return (
    <div className="border-1 border-tertiary-2 rounded-2 w-full lg:w-[338px] h-fit space-y-4 p-4 mt-4 lg:mt-0 text-tertiary-7">
      <div>
        <Text.P.Bold className="pb-2 border-b-1 border-tertiary-2">
          Current appointment
        </Text.P.Bold>
      </div>
      <div className="flex items-center space-x-2">
        <Calendar />
        <Text.P>{dayjs(startTime).format('ddd MMM D [at] h:mmA')}</Text.P>
      </div>
      {therapyType ? (
        <div className="flex items-center space-x-2">
          <User />
          <Text.P>
            {isFirstAppointment
              ? therapyTypeToInitialAppointmentDescription(therapyType)
              : appointmentType}
          </Text.P>
        </div>
      ) : (
        <></>
      )}
      <div className="flex items-center space-x-2">
        <Clock />
        <Text.P>{previousLengthInMinutes} minutes</Text.P>
      </div>
      <div className="flex items-center space-x-2">
        <Video />
        <Text.P>Video session</Text.P>
      </div>
    </div>
  );
};

export default OldAppointmentCard;
