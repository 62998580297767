import { Address } from './types';

export const address = (a: Partial<Address> = {}): Address => ({
  street: '',
  apt_suite: '',
  city: '',
  state: '',
  zip: '',
  ...a,
});
