import login from './login';
import verify from './verify';
import profile from './profile';
import billing from './billing';
import async from './async';
import appointments_v2 from './appointments_v2';
import messaging from './messaging';
import careTeam_v3 from './care-team_v3';
import surveys from './surveys';
import { ToastProps } from '../../components/Toast';
import {
  PageState,
  ProviderOptions,
  SelfPayRates,
  UnauthenticatedState,
  ActiveOrExpiredRelationshipAccount,
  SelectedAccount,
} from '@/utils/types';
import careTeam from './care-team';
import { ModalProps } from '@/components/Modal';
import patientEvents from './patient-events';
import { BannerProps } from '@/components/Banner';

const actions = {
  setSession: (
    payload:
      | {
          email: string;
          expires: number;
        }
      | Record<never, never>
  ) => ({ type: 'setSession', payload } as const),
  refreshSessionTime: (payload: number) =>
    ({ type: 'refreshSessionTime', payload } as const),
  setAccountSelected: (payload?: SelectedAccount) =>
    ({ type: 'setAccountSelected', payload } as const),
  setRelationshipAccounts: (payload: ActiveOrExpiredRelationshipAccount[]) =>
    ({ type: 'setRelationshipAccounts', payload } as const),
  closeMenus: () => ({ type: 'closeMenus' } as const),
  closeModal: () => ({ type: 'closeModal' } as const),
  setUserMenuOpen: (payload: boolean) =>
    ({ type: 'setUserMenuOpen', payload } as const),
  setHamburgerMenuOpen: (payload: boolean) =>
    ({ type: 'setHamburgerMenuOpen', payload } as const),
  setToast: (payload: ToastProps | null) =>
    ({ type: 'setToast', payload } as const),
  setSessionStatus: (payload: UnauthenticatedState['ui']['status']) =>
    ({ type: 'setSessionStatus', payload } as const),
  timeoutSession: () => ({ type: 'timeoutSession' } as const),
  setToken: (payload: string | null) =>
    ({ type: 'setToken', payload } as const),
  setPage: (payload: PageState['path']) =>
    ({ type: 'setPage', payload } as const),
  setModal: (payload: ModalProps | null) =>
    ({ type: 'setModal', payload } as const),
  setBanner: (payload: BannerProps | null) =>
    ({ type: 'setBanner', payload } as const),
  setProviderSpecializations: (payload: Record<string, string[]>) =>
    ({ type: 'setProviderSpecializations', payload } as const),
  setProviderModalities: (payload: Record<string, string[]>) =>
    ({ type: 'setProviderModalities', payload } as const),
  setAdvancedMdOfficeKeyStateMap: (payload: Record<number, string>) =>
    ({ type: 'setAdvancedMdOfficeKeyStateMap', payload } as const),
  setProviderOptions: (payload: ProviderOptions) =>
    ({ type: 'setProviderOptions', payload } as const),
  setAcceptedInsurances: (payload: Record<string, string[]>) =>
    ({ type: 'setAcceptedInsurances', payload } as const),
  setSelfPayRates: (payload: SelfPayRates) =>
    ({ type: 'setSelfPayRates', payload } as const),
  addUsedAvailabilitySlot: (payload: { npi: number; slot: number }) =>
    ({
      type: 'addUsedAvailabilitySlot',
      payload,
    } as const),
};

export type Action = ReturnType<
  | typeof login[keyof typeof login]
  | typeof verify[keyof typeof verify]
  | typeof actions[keyof typeof actions]
  | typeof profile[keyof typeof profile]
  | typeof billing[keyof typeof billing]
  | typeof async[keyof typeof async]
  | typeof appointments_v2[keyof typeof appointments_v2]
  | typeof careTeam[keyof typeof careTeam]
  | typeof patientEvents[keyof typeof patientEvents]
  | typeof messaging[keyof typeof messaging]
  | typeof careTeam_v3[keyof typeof careTeam_v3]
  | typeof surveys[keyof typeof surveys]
>;

export default {
  ...actions,
  login,
  profile,
  billing,
  async,
  verify,
  appointments_v2,
  messaging,
  careTeam,
  careTeam_v3,
  surveys,
  patientEvents,
};
