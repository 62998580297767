import {
  createContext,
  Dispatch,
  Reducer,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import * as Sentry from '@sentry/react';
import reducer, {
  State,
  isAuthenticated,
  isMinor1317,
  hasSelectedAccount,
  defaultState,
} from './state/reducer';
import actions, { Action } from './state/actions';
import getCookies from './utils/cookies';
import Container from './Container';
import { AuthenticatedState, RelationshipAccounts } from './utils/types';
import fetch from './utils/fetch';
import SessionExpiryWarning from './SessionExpiryWarning';
import { useSetSession, maybeSetSelectedAccount } from './utils/session';
import Unauthenticated from './Unauthenticated';
import { ErrorBoundary } from '@sentry/react';
import ErrorPage from './pages/ErrorPage';
import SelectAccount from './login/SelectAccount';
import AccountSwitchWarning from './AccountSwitchWarning';
import MinorManagedCarePage from './pages/MinorManagedCarePage';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { ldInitConfig } from '@/utils/use-feature-flags';

export const StateContext = createContext<{
  state: AuthenticatedState;
  dispatch: Dispatch<Action>;
}>({
  state: {} as any,
  dispatch: () => {
    console.error('dispatch called before initialization');
  },
});

export const MobileContext = createContext<boolean>(false);

const App = () => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(
    reducer,
    defaultState
  );
  const [checkingAuth, setCheckingAuth] = useState(true);
  const setSession = useSetSession(dispatch);

  const query = new URLSearchParams(window.location.search);

  // only get magic token on page load
  const magicToken = useMemo(() => {
    return query.get('token');
  }, []);

  useEffect(() => {
    setCheckingAuth(true);
    dispatch(actions.setToken(magicToken));
    const whoamiCookie = () => getCookies()['__Host-whoami'];
    const whoami = () => JSON.parse(whoamiCookie() || '{}');
    const createSession =
      magicToken && !('email' in whoami())
        ? fetch
            .json(`/session`, { method: 'POST', body: { token: magicToken } })
            .catch((r) => {
              if (r.json) {
                r.json().then(({ error }: { error: any }) => {
                  if (error && typeof error.match === 'function') {
                    if (error.match(/expired/)) {
                      dispatch(actions.setSessionStatus('magic_link_expired'));
                    } else if (error.match(/used/)) {
                      dispatch(actions.setSessionStatus('magic_link_used'));
                    }
                  }
                });
              } else {
                Sentry.captureException(r, {
                  extra: {
                    message: 'error with no json() method on session create',
                  },
                });
              }
            })
            .then((r) => {
              if (r && !r.verified) {
                dispatch(actions.setToken(r.token));
                if (r.verificationType === 'phone_number') {
                  dispatch(actions.setSessionStatus('unverified_phone'));
                } else {
                  dispatch(actions.setSessionStatus('unverified_birthdate'));
                }
                return Promise.reject();
              }
            })
        : magicToken
        ? fetch
            .json(`/magic_token?token=${magicToken}`, {
              method: 'DELETE',
            })
            .catch() // delete magic token if user is already authenticated and using a link with a magic token
        : Promise.resolve(); // session should already be created if no magic token

    const newQuery = new URLSearchParams(query);
    newQuery.delete('token');
    const newQueryString = newQuery.toString();
    history.pushState(
      null,
      '',
      `${location.href.split('?')[0]}${
        newQueryString ? '?' + newQueryString : ''
      }`
    );

    createSession
      // set session has to come first before getting relationship accounts as relationship accounts lives on auth state
      .then(() => setSession())
      .then(async () => {
        // without this, we can potentially get a rejected promise error on load
        const csrfCookie = () => getCookies()['__Host-csrf'];
        if (csrfCookie()) {
          const accounts: RelationshipAccounts = await fetch
            .json('/api/get_relationship_accounts', {
              method: 'GET',
            })
            .catch(() => {
              dispatch(
                actions.async.setLoading({
                  key: 'patientData',
                  loadingState: 'error',
                })
              );
            });

          await maybeSetSelectedAccount(accounts, dispatch);
          dispatch(actions.setRelationshipAccounts(accounts));
        }

        return Promise.resolve();
      })
      .catch()
      .finally(() => setCheckingAuth(false));
  }, [magicToken]);

  if (checkingAuth) {
    return null;
  }

  const mobile = window.matchMedia('(max-width: 640px)').matches;

  return (
    <ErrorBoundary fallback={<ErrorPage />}>
      <MobileContext.Provider value={mobile}>
        {isAuthenticated(state) ? (
          isMinor1317(state) ? (
            <StateContext.Provider value={{ state, dispatch }}>
              <MinorManagedCarePage />
            </StateContext.Provider>
          ) : hasSelectedAccount(state) ? (
            <StateContext.Provider value={{ state, dispatch }}>
              <Container />
              <SessionExpiryWarning />
              <AccountSwitchWarning />
            </StateContext.Provider>
          ) : (
            <StateContext.Provider value={{ state, dispatch }}>
              <SelectAccount />
            </StateContext.Provider>
          )
        ) : (
          <Unauthenticated state={state} dispatch={dispatch} />
        )}
      </MobileContext.Provider>
    </ErrorBoundary>
  );
};

export default withLDProvider(ldInitConfig)(App);
