import Text from './Text';

type TabProps<T> = {
  tab: { name: string; value: T };
  isActive: boolean;
  onClick: (value: T) => void;
};

const Tab = <T extends string>({ tab, isActive, onClick }: TabProps<T>) => {
  // if active, give it a border bottom of 4px, radius 10px, change font color to primary
  const handleClick = () => onClick(tab.value);
  return (
    <div className="flex flex-col">
      <Text.P.Bold
        onClick={handleClick}
        className={`pb-2 cursor-pointer ${
          isActive ? 'text-primary-3' : 'text-tertiary-5'
        }`}
      >
        {tab.name}
      </Text.P.Bold>
      {isActive ? (
        <span data-testid="underline" className="h-1 bg-primary-3 rounded-3" />
      ) : null}
    </div>
  );
};

export default Tab;
