export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const str = reader.result?.toString();
      if (!str) {
        return reject('File content is empty');
      }
      // strip out metadata from beginning
      const data = str.replace(/^data:(.*,)?/, '');
      resolve(data);
    };
    reader.onerror = (error) => reject(error);
  });
