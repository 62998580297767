import React, { Ref, useRef } from 'react';
import { Override } from '../utils/types';
import { changeEventWrapper } from '../utils/on-change';
import { distance } from '../utils/distance';

type OverrideProps = {
  value?: string;
  onEnter?: () => unknown;
  state?: 'error' | '';
  onChange?: (value: string) => unknown;
};

export type DateInputProps = Override<
  JSX.IntrinsicElements['input'],
  OverrideProps
>;

const MonthInput = React.forwardRef(
  (
    { value = '', onChange, onEnter, ...props }: DateInputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    return (
      <input
        {...props}
        value={value}
        name="Month of birth"
        onKeyUp={onEnter ? (e) => e.key === 'Enter' && onEnter() : undefined}
        onChange={changeEventWrapper(onChange)}
        className="border-0 focus-visible:outline-none w-7"
        ref={ref}
      ></input>
    );
  }
);

const DayInput = React.forwardRef(
  (
    { value = '', onChange, onEnter, ...props }: DateInputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    return (
      <input
        {...props}
        value={value}
        name="Day of birth"
        onKeyUp={onEnter ? (e) => e.key === 'Enter' && onEnter() : undefined}
        onChange={changeEventWrapper(onChange)}
        className="border-0 focus-visible:outline-none w-6"
        ref={ref}
      ></input>
    );
  }
);

const YearInput = React.forwardRef(
  (
    { value = '', onChange, onEnter, ...props }: DateInputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    return (
      <input
        {...props}
        value={value}
        name="Year of birth"
        onKeyUp={onEnter ? (e) => e.key === 'Enter' && onEnter() : undefined}
        onChange={changeEventWrapper(onChange)}
        className="border-0 focus-visible:outline-none w-12"
        ref={ref}
      ></input>
    );
  }
);

// format is mm/dd/yyyy
const DateInput = ({
  state = '',
  value = '//',
  onEnter,
  onChange,
  placeholder,
}: DateInputProps) => {
  const monthInput = useRef<HTMLInputElement>(null);
  const dayInput = useRef<HTMLInputElement>(null);
  const yearInput = useRef<HTMLInputElement>(null);
  const borderColor =
    state === 'error' ? 'border-warning-1' : 'border-tertiary-3';

  const [month = '', day = '', year = ''] = value.split('/');
  const [
    monthPlaceholder = 'MM',
    dayPlaceholder = 'DD',
    yearPlaceholder = 'YYYY',
  ] = placeholder?.split('/') || [];

  const onMonthChange = (m: string) => {
    if (!onChange) {
      return;
    }

    const num = Number(m);
    // only allow numbers
    if (Number.isNaN(num)) {
      return;
    }
    // if new number makes an invalid month, use it for the day if there is no day
    if (num > 12) {
      if (!day.length) {
        dayInput.current?.focus();
        onDayChange(m[m.length - 1]);
      }
      return;
    }
    onChange(`${m}/${day}/${year}`);

    // 1 is the only number that can be followed by another number and still be a valid month,
    // otherwise go to the next input
    if (num > 1 || m.length === 2) {
      dayInput.current?.focus();
    }
  };

  // triggers onEnter if all fields are filled, otherwise calls ifNotFilled
  const enterIfFilledIn = (ifNotFilled?: () => unknown) => {
    if (onEnter && month.length && day.length && year.length) {
      return onEnter;
    }
    return ifNotFilled;
  };

  // go to next input if date isn't filled in
  const onMonthEnter = enterIfFilledIn(() => {
    dayInput.current?.focus();
  });

  // go to next input if pressing right at end of input
  const onMonthKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'ArrowRight' &&
      monthInput.current?.selectionStart === month.length
    ) {
      dayInput.current?.focus();
    }
  };

  const onDayChange = (d: string) => {
    if (!onChange) {
      return;
    }
    const num = Number(d);
    // only allow valid numbers
    if (Number.isNaN(num)) {
      return;
    }

    if (num > 31) {
      if (!year.length) {
        yearInput.current?.focus();
        onYearChange(d[d.length - 1]);
      }
      return;
    }

    onChange(`${month}/${d}/${year}`);

    // 3 is the only number that can be followed by another number and still be a valid day,
    // otherwise go to the next input
    if (num > 3 || d.length === 2) {
      yearInput.current?.focus();
    }
  };

  // go to next input if date isn't filled in
  const onDayEnter = enterIfFilledIn(() => {
    yearInput.current?.focus();
  });

  const onDayKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // go to previous input on backspace if empty, or if pressing back arrow
    if (
      (e.key === 'Backspace' && day.length === 0) ||
      (e.key === 'ArrowLeft' && dayInput.current?.selectionStart === 0)
    ) {
      monthInput.current?.focus();
    }
    // go to next input if pressing right at end of input
    if (
      e.key === 'ArrowRight' &&
      dayInput.current?.selectionStart === day.length
    ) {
      yearInput.current?.focus();
    }
  };

  const onYearChange = (y: string) => {
    if (!onChange) {
      return;
    }
    const num = Number(y);
    if (Number.isNaN(num) || num > 9999) {
      return;
    }

    onChange(`${month}/${day}/${y}`);
  };

  // go to previous input on backspace if empty
  const onYearKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      (e.key === 'Backspace' && year.length === 0) ||
      (e.key === 'ArrowLeft' && yearInput.current?.selectionStart === 0)
    ) {
      dayInput.current?.focus();
    }
  };

  // if clicking in the containing div, snap focus to:
  // 1. first empty input, or
  // 2. closest input
  const onClick = (e: React.MouseEvent) => {
    if (
      [monthInput.current, dayInput.current, yearInput.current].includes(
        e.target as HTMLInputElement
      )
    ) {
      return;
    }
    if (!month.length) {
      monthInput.current?.focus();
      return;
    }
    if (!day.length) {
      dayInput.current?.focus();
      return;
    }
    if (!year.length) {
      yearInput.current?.focus();
      return;
    }
    const monthClientRect = monthInput.current!.getBoundingClientRect();
    const monthRect = {
      min: monthClientRect,
      max: {
        x: monthClientRect.x + monthClientRect.width,
        y: monthClientRect.y + monthClientRect.height,
      },
    };
    const dayClientRect = dayInput.current!.getBoundingClientRect();
    const dayRect = {
      min: dayClientRect,
      max: {
        x: dayClientRect.x + dayClientRect.width,
        y: dayClientRect.y + dayClientRect.height,
      },
    };
    const yearClientRect = yearInput.current!.getBoundingClientRect();
    const yearRect = {
      min: yearClientRect,
      max: {
        x: yearClientRect.x + yearClientRect.width,
        y: yearClientRect.y + yearClientRect.height,
      },
    };
    const dMonth = distance(monthRect, { x: e.clientX, y: e.clientY });
    const dDay = distance(dayRect, { x: e.clientX, y: e.clientY });
    const dYear = distance(yearRect, { x: e.clientX, y: e.clientY });
    const min = Math.min(dMonth, dDay, dYear);
    if (min === dMonth) {
      monthInput.current?.focus();
    } else if (min === dDay) {
      dayInput.current?.focus();
    } else if (min === dYear) {
      yearInput.current?.focus();
    }
  };

  return (
    <div
      className={`${borderColor} border-1 rounded pt-3 pr-2 pb-3 pl-2 text-tertiary-6 w-full`}
      onClick={onClick}
    >
      <div className="flex justify-between max-w-[150px]">
        <MonthInput
          value={month}
          placeholder={monthPlaceholder}
          onChange={onMonthChange}
          onKeyDown={onMonthKeyDown}
          onEnter={onMonthEnter}
          ref={monthInput}
        />
        {'/'}
        <DayInput
          value={day}
          placeholder={dayPlaceholder}
          onChange={onDayChange}
          onKeyDown={onDayKeyDown}
          onEnter={onDayEnter}
          ref={dayInput}
        />
        {'/'}
        <YearInput
          value={year}
          placeholder={yearPlaceholder}
          onChange={onYearChange}
          ref={yearInput}
          onKeyDown={onYearKeyDown}
          onEnter={enterIfFilledIn()}
        />
      </div>
    </div>
  );
};

// format is mm/yy
const ExpirationDateInput = ({
  state = '',
  value = '/',
  onChange,
}: Omit<DateInputProps, 'onEnter'>) => {
  const [month = '', year = ''] = value.split('/');
  const monthInput = useRef<HTMLInputElement>(null);
  const yearInput = useRef<HTMLInputElement>(null);
  const borderColor =
    state === 'error' ? 'border-warning-1' : 'border-tertiary-3';

  const onMonthChange = (m: string) => {
    if (!onChange) {
      return;
    }

    const num = Number(m);
    // only allow numbers
    if (Number.isNaN(num)) {
      return;
    }
    // if new number makes an invalid month, use it for the year if there is no year
    if (num > 12) {
      if (!year.length) {
        yearInput.current?.focus();
        onYearChange(m[m.length - 1]);
      }
      return;
    }
    onChange(`${m}/${year}`);

    // 1 is the only number that can be followed by another number and still be a valid month,
    // otherwise go to the next input
    if (num > 1) {
      yearInput.current?.focus();
    }
  };

  // go to next input if pressing right at end of input
  const onMonthKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'ArrowRight' &&
      monthInput.current?.selectionStart === month.length
    ) {
      yearInput.current?.focus();
    }
  };

  const onYearChange = (y: string) => {
    if (!onChange) {
      return;
    }
    const num = Number(y);
    if (Number.isNaN(num) || num > 99) {
      return;
    }

    onChange(`${month}/${y}`);
  };

  // go to previous input on backspace if empty
  const onYearKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      (e.key === 'Backspace' && year.length === 0) ||
      (e.key === 'ArrowLeft' && yearInput.current?.selectionStart === 0)
    ) {
      monthInput.current?.focus();
    }
  };

  // if clicking in the containing div, snap focus to closest input
  const onClick = (e: React.MouseEvent) => {
    if (
      [monthInput.current, yearInput.current].includes(
        e.target as HTMLInputElement
      )
    ) {
      return;
    }
    if (!month.length) {
      monthInput.current?.focus();
      return;
    }
    if (!year.length) {
      yearInput.current?.focus();
      return;
    }
    const monthClientRect = monthInput.current!.getBoundingClientRect();
    const monthRect = {
      min: monthClientRect,
      max: {
        x: monthClientRect.x + monthClientRect.width,
        y: monthClientRect.y + monthClientRect.height,
      },
    };
    const yearClientRect = yearInput.current!.getBoundingClientRect();
    const yearRect = {
      min: yearClientRect,
      max: {
        x: yearClientRect.x + yearClientRect.width,
        y: yearClientRect.y + yearClientRect.height,
      },
    };
    const dMonth = distance(monthRect, { x: e.clientX, y: e.clientY });
    const dYear = distance(yearRect, { x: e.clientX, y: e.clientY });
    const min = Math.min(dMonth, dYear);
    if (min === dMonth) {
      monthInput.current?.focus();
    } else if (min === dYear) {
      yearInput.current?.focus();
    }
  };

  return (
    <div
      className={`${borderColor} border-1 rounded pt-3 pr-2 pb-3 pl-2 text-tertiary-6 w-full`}
      onClick={onClick}
    >
      <div className="flex justify-between max-w-[150px]">
        <MonthInput
          value={month}
          placeholder="MM"
          onChange={onMonthChange}
          onKeyDown={onMonthKeyDown}
          ref={monthInput}
        />
        {'/'}
        <YearInput
          value={year}
          placeholder="YY"
          onChange={onYearChange}
          ref={yearInput}
          onKeyDown={onYearKeyDown}
        />
      </div>
    </div>
  );
};

DateInput.Expiration = ExpirationDateInput;

export default DateInput;
