import { useLocation, useParams } from 'react-router-dom';
import Text from '@/components/Text';
import {
  TherapyType,
  isProviderDetail,
  JSONValue,
  ProductArea,
} from '@/utils/types';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { StateContext } from '@/App';
import ProviderHeaderV2 from '@/pages/care-team/ProviderHeaderV2';
import ProviderDetailSection from '@/pages/care-team/ProviderDetailSection';
import AppointmentPicker from '@/pages/care-team/AppointmentPicker';
import Clock from '@/components/icons/Clock';
import Video from '@/components/icons/Video';
import WithPageState from '@/pages/WithPageState';
import useData from '@/state/use-data';
import { WithProvider } from '@/pages/care-team/WithProvider';
import RedirectToCareTeamOnErrorV2 from '@/pages/care-team/RedirectToCareTeamOnErrorV2';
import FullWidthHeader from '@/components/FullWidthHeader';
import Dollar from '@/components/icons/Dollar';
import CostModal from '@/components/Modals/CostModal';
import trackEvent from '@/utils/amplitude';
import actions from '@/state/actions';
import { makeDollars } from '@/utils/format';
type LearnMoreTypes = { cashPayNumber: number };

export default function ChooseAppointmentV2() {
  const { dispatch } = useContext(StateContext);
  const {
    providerNpi: currentProviderNpi,
    newNpi,
    therapyType,
  } = useParams<{
    newNpi: string;
    providerNpi?: string;
    therapyType: TherapyType;
  }>();

  const location = useLocation();
  const closeModal = () => dispatch(actions.setModal(null));

  const openCostModal = useCallback((cashPayNumber: number) => {
    dispatch(
      actions.setModal({
        children: (
          <CostModal
            onClose={closeModal}
            title="Cost of your Session"
            cashPayAmount={makeDollars(cashPayNumber)}
          />
        ),
      })
    );
  }, []);

  const maybeProvider = useMemo(() => {
    return isProviderDetail(location.state?.provider)
      ? location.state.provider
      : null;
  }, [location]);

  const LearnMore = useCallback(
    ({ cashPayNumber }: LearnMoreTypes) => (
      <Text.P.Bold>
        <a
          onClick={() => openCostModal(cashPayNumber)}
          className="block text-primary-3  no-underline"
        >
          Learn more
        </a>
      </Text.P.Bold>
    ),
    []
  );

  const { data, WithData } = useData([
    'patientData',
    'insurance',
    'selfPayRates',
  ]);

  if (!newNpi) {
    return <RedirectToCareTeamOnErrorV2 />;
  }

  return (
    <WithData data={data}>
      {({ patientData, insurance, selfPayRates }) => {
        const cashPayNumber =
          therapyType === 'individual'
            ? selfPayRates.data.individual
            : therapyType === 'psychiatric'
            ? selfPayRates.data.psych_initial
            : selfPayRates.data.couples_family;
        const { patient_record_uuid } = patientData;
        return (
          <WithProvider
            passthrough={maybeProvider}
            patientRecordUuid={patient_record_uuid}
            providerNpi={newNpi}
          >
            {(provider) => (
              <WithPageState page="care-team">
                {({ pageState }) => {
                  useEffect(() => {
                    let product_area: ProductArea =
                      'AddCareTeamProviderDetails';
                    const metadata: { [x: string]: JSONValue } = {
                      proposed_provider_npi: newNpi,
                      provider_results_available_sessions_count:
                        provider.bookable_slots.length,
                    };
                    if (currentProviderNpi) {
                      metadata.original_provider_npi = currentProviderNpi;
                      product_area = 'RematchProviderDetails';
                    }
                    trackEvent({
                      product_area,
                      name: 'page_view',
                      trigger: 'Page load',
                      metadata,
                    });
                  }, []);

                  return (
                    <div className="space-y-10">
                      <FullWidthHeader
                        returnPath="/appointments"
                        returnPage="appointments"
                      >
                        Choose your first appointment
                      </FullWidthHeader>
                      <div className="space-y-5">
                        <ProviderHeaderV2
                          dispatch={dispatch}
                          pageState={pageState}
                          provider={provider}
                          therapyType={therapyType}
                        />
                        <div className="xl:flex relative justify-start items-start gap-x-6 text-left">
                          <div className="w-full xl:w-3/5">
                            <ProviderDetailSection provider={provider} />
                          </div>
                          <div className="w-full xl:w-2/5 mt-5 xl:mt-0">
                            <div className="border border-tertiary-2 rounded-2 px-6 py-8 w-full">
                              <div className="mb-3">
                                <Text.H3>Book your session</Text.H3>
                              </div>
                              <AppointmentPicker
                                generateLinkProps={(newAppointment) => ({
                                  to: currentProviderNpi
                                    ? `/care-team/${currentProviderNpi}/${therapyType}/replace/${newNpi}/confirm-appointment/${newAppointment}`
                                    : `/care-team/${therapyType}/add-new-care-type/${newNpi}/confirm-appointment/${newAppointment}`,
                                  state: {
                                    provider,
                                  },
                                })}
                                provider={provider}
                                therapyType={therapyType as TherapyType}
                                isHold={true}
                              />
                              <div className="border-t border-tertiary-2 flex flex-col items-left  pt-5">
                                <div className="flex  gap-2 items-center mb-3 last:mb-0">
                                  <Clock />
                                  <Text.P>60 minutes</Text.P>
                                </div>
                                <div className="flex  gap-2 items-center mb-3 last:mb-0">
                                  <Video />
                                  <Text.P>Video session</Text.P>
                                </div>

                                <div className="flex  gap-2 items-start mb-3 last:mb-0">
                                  <Dollar className="grow-0 shrink-0 mb-3 last:mb-0" />
                                  {insurance.payment_method ===
                                    'Health Insurance' &&
                                  insurance.network_name &&
                                  provider.insurances.includes(
                                    insurance.network_name.toLowerCase()
                                  ) ? (
                                    <div>
                                      <Text.P>
                                        Covered by{' '}
                                        {insurance.display_name ||
                                          insurance.carrier}
                                      </Text.P>
                                      <Text.P className="text-tertiary-5">
                                        Session cost depends on your insurance,
                                        most people pay $20-50 per session after
                                        meeting their deductible. There
                                        aren&rsquo;t any fees for using Rula.
                                      </Text.P>
                                      <LearnMore
                                        cashPayNumber={cashPayNumber}
                                      />
                                    </div>
                                  ) : insurance.payment_method ===
                                    'Health Insurance' ? (
                                    <div>
                                      <Text.P>
                                        The session will cost{' '}
                                        {makeDollars(cashPayNumber)} since this
                                        provider doesn't accept your insurance.
                                      </Text.P>
                                      <LearnMore
                                        cashPayNumber={cashPayNumber}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      <Text.P>
                                        The session will cost{' '}
                                        {makeDollars(cashPayNumber)}. There
                                        aren&rsquo;t any fees for using Rula.
                                      </Text.P>
                                      <LearnMore
                                        cashPayNumber={cashPayNumber}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }}
              </WithPageState>
            )}
          </WithProvider>
        );
      }}
    </WithData>
  );
}
