const Plant = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="262"
      height="276"
      viewBox="0 0 262 276"
      fill="none"
    >
      <path
        d="M95.1837 99.2755C105.478 113.071 111.607 127.965 115.174 139.504C117.996 148.635 109.777 154.768 101.827 149.463C91.7811 142.759 79.2481 132.644 68.954 118.848C58.6599 105.053 52.5307 90.1591 48.9638 78.6202C46.1413 69.4893 54.3609 63.3558 62.3106 68.6608C72.3567 75.3649 84.8896 85.4803 95.1837 99.2755Z"
        fill="#A5D1C2"
      />
      <path
        d="M184.991 177.896C171.678 182.98 158.43 184.497 148.434 184.732C140.523 184.918 137.494 176.985 143.516 171.851C151.125 165.364 162.013 157.666 175.326 152.583C188.639 147.5 201.887 145.982 211.883 145.748C219.794 145.562 222.823 153.494 216.801 158.628C209.192 165.115 198.304 172.813 184.991 177.896Z"
        fill="#A5D1C2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.4314 123.865C120.593 156.121 123.466 201.661 101.679 237.102C101.38 237.589 101.518 238.232 101.996 238.539C102.473 238.846 103.109 238.7 103.409 238.213C125.672 202.016 122.741 155.495 96.0184 122.547L79.5206 102.205C79.1618 101.763 78.5156 101.699 78.0774 102.063C77.6391 102.427 77.5748 103.081 77.9336 103.523L94.4314 123.865Z"
        fill="#262626"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.485 168.905C132.359 178.388 110.274 215.2 118.749 251.452C118.878 252.006 118.545 252.576 117.994 252.72C117.443 252.865 116.883 252.53 116.753 251.975C108.002 214.618 130.758 176.676 167.985 166.903L184.158 162.658C184.709 162.513 185.267 162.844 185.405 163.396C185.543 163.949 185.208 164.514 184.657 164.659L168.485 168.905Z"
        fill="#262626"
      />
      <path
        d="M184.981 111.888C171.719 129.231 155.673 141.873 142.831 150.226C132.669 156.836 122.336 148.934 126.052 137.395C130.748 122.814 138.744 104.015 152.006 86.6723C165.268 69.3293 181.314 56.687 194.156 48.3341C204.318 41.7244 214.651 49.6262 210.935 61.1649C206.239 75.7464 198.243 94.5449 184.981 111.888Z"
        fill="#5937E2"
      />
      <path
        d="M184.981 111.888C171.719 129.231 155.673 141.873 142.831 150.226C132.669 156.836 122.336 148.934 126.052 137.395C130.748 122.814 138.744 104.015 152.006 86.6722C165.268 69.3292 181.314 56.6869 194.156 48.334C204.318 41.7243 214.651 49.6261 210.935 61.1648C206.239 75.7463 198.243 94.5448 184.981 111.888Z"
        fill="#009F79"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M134.171 145.441C109.038 178.657 115.153 226.072 147.712 251.853C148.158 252.206 148.249 252.858 147.906 253.313C147.562 253.767 146.913 253.851 146.467 253.498C113.006 227.021 106.716 178.306 132.537 144.182L179.238 82.4638C179.581 82.0095 180.226 81.9231 180.677 82.2707C181.128 82.6184 181.216 83.2685 180.872 83.7227L134.171 145.441Z"
        fill="#262626"
      />
      <path
        d="M69 192H182V219.5C182 250.704 156.704 276 125.5 276C94.2959 276 69 250.704 69 219.5V192Z"
        fill="#C8C0E8"
      />
    </svg>
  );
};

export default Plant;
