import { StateContext } from '@/App';
import { useContext } from 'react';
import actions from '@/state/actions';
import Text from '@/components/Text';

const useClipboard = () => {
  const { dispatch } = useContext(StateContext);
  const writeToClipboard = (textToCopy: string, textToShow?: string) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        dispatch(
          actions.setToast({
            text: (
              <Text.P>
                {textToShow || textToCopy} was copied to your clipboard
              </Text.P>
            ),
            variant: 'success',
            onClose: () => dispatch(actions.setToast(null)),
          })
        );
      })
      .catch(() => {
        dispatch(
          actions.setToast({
            text: (
              <Text.P>Failed to copy email to clipboard: {textToCopy}</Text.P>
            ),
            variant: 'warning',
            onClose: () => dispatch(actions.setToast(null)),
          })
        );
      });
  };
  return [writeToClipboard];
};

export default useClipboard;
